import { View, Text } from "react-native";
import React, { useMemo } from "react";
import Button from "@/components/ui/Button";
import { scale } from "react-native-size-matters";
import DropdownArrow from "@/components/icons/DropdownArrow";
import CloseCross from "@/components/icons/CloseCross";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import { setSkill } from "@/redux/slices/clockings/functions";
import IShift from "@/types/IShift.model";

interface Props {
  active_shift?: IShift;
  onSkillPress: () => void;
}

const SelectSkillButton: React.FC<Props> = ({ active_shift, onSkillPress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { active_department, skills } = useSelector(
    (state: AppState) => state.app
  );
  const { active_user, active_skill } = useSelector(
    (state: AppState) => state.clockings
  );

  const show_skills = useMemo(() => {
    return (
      active_department?.params.freeclockin &&
      skills &&
      skills?.length &&
      ((!active_shift &&
        active_user?.skills &&
        active_user.skills.length > 0) ||
        (active_shift &&
          active_shift?.skills &&
          active_shift.skills.length > 1))
    );
  }, [active_department, active_shift, skills, active_user]);

  if (!show_skills) return null;

  const clearActiveSkill = () => {
    dispatch(setSkill(null));
  };

  return (
    <Button
      value={active_skill ? active_skill.name : t("GLOBAL.SELECT_SKILL")}
      width={width / 2.5}
      icon={
        active_skill ? (
          <Button
            value=""
            icon={<CloseCross color="#FFFFFF" />}
            onPress={clearActiveSkill}
            no_style
          />
        ) : (
          <DropdownArrow />
        )
      }
      onPress={onSkillPress}
      justifyContent="space-between"
      marginTop={scale(10)}
      marginHorizontal={scale(5)}
    />
  );
};

export default SelectSkillButton;
