import { View, Text } from "react-native";
import React from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";
import { scale } from "react-native-size-matters";

interface Props {
  onCheckout: () => Promise<void>;
  flex?: number;
  secondary?: boolean;
}

const ClockOutButton: React.FC<Props> = ({ onCheckout, flex, secondary }) => {
  const { t } = useTranslation();
  const { clock_loading } = useSelector((state: AppState) => state.clockings);

  return (
    <Button
      value={t("PROFILE.CLOCK_OUT")}
      onPress={onCheckout}
      loading={clock_loading}
      style={{
        flex: flex || 0.75,
        marginRight: scale(2),
      }}
      primary={!secondary}
      secondary={secondary}
    />
  );
};

export default React.memo(ClockOutButton);
