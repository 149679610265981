import { ParamList, SHYFTER } from "@/globals";
import { getPosData, registerTablet } from "@/redux/slices/app/functions";
import { reset } from "@/redux/slices/clockings/functions";
import { AppDispatch, AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import CloseCross from "@icons/CloseCross";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  relog?: boolean;
  apiKey?: string;
}

const Close: React.FC<Props> = ({ relog, apiKey }) => {
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const { theme } = useSelector((state: AppState) => state.app);
  const dispatch = useDispatch<AppDispatch>();

  const defaultOnPress = async () => {
    if (relog && apiKey) {
      await dispatch(registerTablet(apiKey, true));
      await dispatch(getPosData());
    } else {
      dispatch(reset());
    }
    navigation.navigate("Start");
  };

  const styles = StyleSheet.create({
    container: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: scale(5),
      margin: scale(15),
      backgroundColor: theme.secondary!,
      borderRadius: scale(20),
    },
  });

  return (
    <TouchableOpacity style={styles.container} onPress={defaultOnPress}>
      <CloseCross />
    </TouchableOpacity>
  );
};

export default React.memo(Close);
