import { createSlice } from '@reduxjs/toolkit';
import { Dimensions } from 'react-native';

const initialState: IDeviceState = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDimensions: (state, { payload }) => {
      state.width = payload.width;
      state.height = payload.height;
    },
    reset: (state) => {
      state.width = Dimensions.get('window').width;
      state.height = Dimensions.get('window').height;
    },
  },
});

export const actions = deviceSlice.actions;
export default deviceSlice.reducer;

interface IDeviceState {
  width: number;
  height: number;
}
