import IAttribute from '@/types/IAttribute.model';
import IResource from '@/types/IResource.model';
import { getContrast } from '@/utils';
import { AppState } from '@redux/store';
import Text from '@ui/Text';
import { Camera } from 'expo-camera';
import React, { useCallback } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';
import AttributeItem from './AttributeItem';

interface Props {
  from: 'home' | 'profile';
  resource: IResource;
  setModalVisible: (visible: boolean) => void;
  camera: React.RefObject<Camera>;
}

const ResourceItem: React.FC<Props> = ({
  from,
  resource,
  camera,
  setModalVisible,
}) => {
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_user } = useSelector((state: AppState) => state.clockings);

  const renderAttributes = useCallback(({ item }: { item: IAttribute }) => {
    return (
      <AttributeItem
        from={from}
        attribute={item}
        setModalVisible={setModalVisible}
        camera={camera}
      />
    );
  }, []);

  const keyExtractor = useCallback((item: IAttribute) => item.id, []);

  const attributes =
    active_user && active_user.attributes
      ? resource.attributes.filter((attribute) => {
          return active_user.attributes
            ? active_user.attributes.includes(attribute.id)
            : true;
        })
      : resource.attributes;

  return (
    <View style={styles.container}>
      <Text size={scale(7)} color={getContrast(theme.secondary!)}>
        {resource.name}
      </Text>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={attributes}
        renderItem={renderAttributes}
        keyExtractor={keyExtractor}
        initialNumToRender={resource.attributes.length}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: scale(15),
  },
});

export default React.memo(ResourceItem);
