import { reset } from '@/redux/slices/clockings/functions';
import { AppState } from '@/redux/store';
import Clock from '@components/Clock';
import { ParamList, TIME_FORMAT_FULL } from '@globals';
import CompanyLogo from '@icons/CompanyLogo';
import Pause from '@icons/Pause';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import DefaultContainer from '@screens/default/DefaultContainer';
import Tooltip from '@screens/message/components/Tooltip';
import Button from '@ui/Button';
import Text from '@ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';

interface Props {}

const INACTIVITY_TIMER = 10000;

const MessageScreen: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme, active_department } = useSelector(
    (state: AppState) => state.app
  );
  const { active_user } = useSelector((state: AppState) => state.clockings);
  const {
    params: { message, tooltip, icon_name, from, time },
  } = useRoute<RouteProp<ParamList, 'Message'>>();

  let icon;
  switch (icon_name) {
    case 'pause': {
      icon = <Pause />;
      break;
    }
    default:
      break;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      redirect();
    }, INACTIVITY_TIMER);
    return () => {
      clearTimeout(timeout);
    };
  }, [from]);

  const onPress = () => {
    redirect();
  };

  const redirect = () => {
    switch (from) {
      case 'home': {
        navigation.navigate('Profile');
        break;
      }
      case 'profile': {
        reset();
        navigation.navigate('Start');
        break;
      }
      case 'start': {
        navigation.navigate('Profile');
        break;
      }
      default:
        break;
    }
  };

  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      width: width / 1.5,
    },
  });

  return (
    <DefaultContainer withCircle>
      <Clock withTime />
      <View style={styles.container}>
        <Tooltip message={tooltip} icon={icon} arrowSide='bottom' />
        <Text size={scale(20)} bold uppercase marginTop={scale(10)} center>
          {message}
        </Text>
        {active_department?.params.worked_hours && time ? (
          <Text
            semi
            size={scale(30)}
            marginTop={scale(20)}
            marginBottom={scale(20)}
          >
            {time}
          </Text>
        ) : null}
        <Button
          value={t('GLOBAL.CLOSE')}
          primary
          onPress={onPress}
          marginTop={scale(10)}
        />
      </View>
      <CompanyLogo bottom custom={theme.logo} />
    </DefaultContainer>
  );
};

export default MessageScreen;
