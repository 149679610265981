import {
  changeSection,
  setChangeSectionLoading,
  setSection,
} from "@/redux/slices/clockings/functions";
import { getContrast } from "@/utils";
import ISection from "@models/ISection.model";
import { AppDispatch, AppState } from "@redux/store";
import Text from "@ui/Text";
import { Camera } from "expo-camera";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity } from "react-native";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import * as ImageManipulator from "expo-image-manipulator";

interface Props {
  from: "home" | "profile";
  section: ISection | null;
  setModalVisible: (visible: boolean) => void;
  camera: React.RefObject<Camera>;
}

const SectionItem: React.FC<Props> = ({
  from,
  section,
  camera,
  setModalVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { theme, active_department } = useSelector(
    (state: AppState) => state.app
  );
  const { active_clocking, active_section } = useSelector(
    (state: AppState) => state.clockings
  );
  const is_active = active_section?.id === section?.id;

  const onPress = async () => {
    if (from == "home") {
      dispatch(setSection(section));
      setModalVisible(false);
    }
    if (from == "profile") {
      try {
        dispatch(setChangeSectionLoading(true));
        let picture = null;
        if (active_department?.params.tablet_picture) {
          const image = await camera.current?.takePictureAsync({
            quality: 0.1,
            skipProcessing: true,
            base64: true,
          });
          if (image) {
            picture = await ImageManipulator.manipulateAsync(image.uri, [], {
              compress: 0.3,
              format: ImageManipulator.SaveFormat.JPEG,
              base64: true,
            });
          }
        }

        dispatch(
          changeSection({
            clocking_id: active_clocking!.id,
            departmentId: active_department!.id,
            section_id: section ? section.id : null,
            time: moment().unix().toString(),
            picture: picture ? picture?.base64 : undefined,
          })
        );
      } catch (err) {
        console.log(err);
      }
      setModalVisible(false);
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text
        size={scale(10)}
        color={is_active ? theme.error! : getContrast(theme.secondary!)}
      >
        {section ? section.name : t("GLOBAL.NO_SECTION")}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: scale(15),
  },
});

export default React.memo(
  SectionItem,
  (prev, next) => prev.section?.id === next.section?.id
);
