import { View, Text, Platform } from "react-native";
import React, { useCallback } from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import { Camera } from "expo-camera";
import * as Network from "expo-network";
import { breakOut, setBreakLoading } from "@/redux/slices/clockings/functions";
import moment from "moment";
import { scale } from "react-native-size-matters";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as FileSystem from "expo-file-system";
import * as ImageManipulator from "expo-image-manipulator";

interface Props {
  camera: React.RefObject<Camera>;
}

const BreakOutButton: React.FC<Props> = ({ camera }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { active_department } = useSelector((state: AppState) => state.app);
  const { active_clocking, active_break, break_loading } = useSelector(
    (state: AppState) => state.clockings
  );

  const onBreakOut = useCallback(async () => {
    try {
      dispatch(setBreakLoading(true));
      // setLastInteraction(Date.now());
      let picture = null;
      if (active_department?.params.tablet_picture) {
        const image = await camera.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      const network = await Network.getNetworkStateAsync();
      const { granted } = await Camera.getCameraPermissionsAsync();

      await dispatch(
        breakOut({
          clocking_id: active_clocking!.id,
          break_id: active_break!.id,
          end: moment().unix().toString(),
          picture: picture ? picture?.base64 : undefined,
          infos: {
            version: Constants.expoConfig?.version,
            os: Platform.OS,
            model_name: Device.modelName,
            has_internet: network.isConnected,
            internet_reachable: network.isInternetReachable,
            camera_granted: granted,
            notifications: false,
            total_disk_space:
              Platform.OS !== "web"
                ? `${await FileSystem.getTotalDiskCapacityAsync()}`
                : null,
            free_disk_space:
              Platform.OS !== "web"
                ? `${await FileSystem.getFreeDiskStorageAsync()}`
                : null,
            ram: Device.totalMemory ? `${Device.totalMemory}` : null,
          },
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [active_department, camera, active_clocking, active_break]);

  return (
    <Button
      value={t("PROFILE.END_BREAK")}
      primary
      onPress={onBreakOut}
      loading={break_loading}
      style={{
        flex: 0.75,
        marginRight: scale(2),
      }}
    />
  );
};

export default React.memo(BreakOutButton);
