import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import Pause from "@icons/Pause";
import Text from "@ui/Text";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Image, StyleSheet, View } from "react-native";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  ratio?: number;
}

const ProfileImage: React.FC<Props> = ({ ratio }) => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_user, active_break } = useSelector(
    (state: AppState) => state.clockings
  );

  const disabled = useMemo(() => {
    return active_break !== null;
  }, [active_break]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        image_container: {
          alignItems: "center",
          justifyContent: "center",
        },
        image_background: {
          width: scale(75),
          height: scale(75),
          borderRadius: 250,
        },
        image: {
          width: scale(60),
          height: scale(60),
          borderRadius: 200,
        },
        user_image: {
          width: scale(60),
          height: scale(60),
          backgroundColor: theme.primary!,
          borderRadius: 1000,
          justifyContent: "center",
          alignItems: "center",
          marginRight: scale(10),
        },
      }),
    [theme]
  );

  return (
    <View
      style={[
        styles.image_container,
        { transform: [{ scale: ratio ? ratio : 1 }] },
      ]}
    >
      <View style={[styles.image, { opacity: disabled ? 0.05 : 1 }]}>
        {active_user?.picture ? (
          <Image
            style={styles.image}
            source={{
              uri: active_user.picture,
            }}
          />
        ) : (
          <View style={styles.user_image}>
            <Text
              size={scale(15)}
              uppercase
              bold
              color={getContrast(theme.primary!)}
            >
              {active_user?.initials}
            </Text>
          </View>
        )}
      </View>
      {disabled ? (
        <View style={{ position: "absolute", alignItems: "center" }}>
          <Pause color="#fff" />
          <Text white bold size={scale(10)}>
            {t("PROFILE.ON_BREAK")}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(ProfileImage);
