import React from "react";
import Text from "@components/ui/Text";
import { scale } from "react-native-size-matters";
import { useTranslation } from "react-i18next";
import { TIME_FORMAT } from "@/globals";

interface Props {
  active_shift_start_moment: moment.Moment;
  active_shift_end_moment: moment.Moment;
}

const PlannedShiftText: React.FC<Props> = ({
  active_shift_start_moment,
  active_shift_end_moment,
}) => {
  const { t } = useTranslation();

  return (
    <Text size={scale(10)}>
      {t("HOME.SHIFT_PLANNED")}{" "}
      <Text bold size={scale(10)}>
        {t("GLOBAL.FROM")} {active_shift_start_moment.format(TIME_FORMAT)}{" "}
        {t("GLOBAL.TO")} {active_shift_end_moment.format(TIME_FORMAT)}
      </Text>
    </Text>
  );
};

export default React.memo(PlannedShiftText);
