import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, es, fr, nl } from './locales';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    fr: {
      translation: fr,
    },
    nl: {
      translation: nl,
    },
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
  fallbackLng: 'en',
});

export default i18n;
