import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import Separator from '@components/Separator';
import IUser from '@models/IUser.model';
import Text from '@ui/Text';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';

interface Props {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

const User: React.FC<Props> = ({ user, setUser }) => {
  const { theme } = useSelector((state: AppState) => state.app);

  const onPress = () => {
    setUser(user);
  };

  const styles = StyleSheet.create({
    container: {
      marginVertical: scale(2),
      padding: scale(5),
      flexDirection: 'row',
      alignItems: 'center',
    },
    user_image: {
      width: scale(25),
      height: scale(25),
      backgroundColor: theme.primary!,
      borderRadius: 50,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: scale(10),
    },
    image: {
      width: scale(25),
      height: scale(25),
      borderRadius: 50,
      marginRight: scale(10),
    },
  });

  return (
    <>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        {user.picture ? (
          <Image
            style={styles.image}
            source={{
              uri: user.picture,
            }}
          />
        ) : (
          <View style={styles.user_image}>
            <Text
              size={scale(10)}
              uppercase
              bold
              color={getContrast(theme.primary!)}
            >
              {user.initials}
            </Text>
          </View>
        )}
        <Text size={scale(10)} style={{ flex: 1 }}>
          {user.displayName}
        </Text>
      </TouchableOpacity>
      <Separator />
    </>
  );
};

export default React.memo(User);
