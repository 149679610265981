import RemoveApiKey from '@/components/RemoveApiKey';
import Time from '@/components/Time';
import { ParamList } from '@/globals';
import CompanyLogo from '@icons/CompanyLogo';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack/lib/typescript/src/types';
import { AppDispatch, AppState } from '@redux/store';
import DefaultContainer from '@screens/default/DefaultContainer';
import Text from '@ui/Text';
import 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';

interface Props {}

const StartScreen: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const dispatch = useDispatch<AppDispatch>();
  const focused = useIsFocused();
  const { width } = Dimensions.get('window');
  const { api_key, section_id, sections, theme, active_department } =
    useSelector((state: AppState) => state.app);

  const onPress = () => {
    navigation.navigate('Pin');
  };

  const styles = StyleSheet.create({
    main_container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: width,
      zIndex: 1,
    },
    tap_to_start: {
      marginTop: scale(10),
    },
  });

  if (!focused) return null;

  const section = sections?.find((section) => section.id == section_id);

  return (
    <DefaultContainer>
      <TouchableOpacity style={styles.main_container} onPress={onPress}>
        <Text size={scale(15)} marginTop={scale(15)} uppercase>
          {active_department?.company}
        </Text>
        <Text
          size={scale(10)}
          marginTop={scale(15)}
          marginBottom={scale(-15)}
          color={section?.color || '#FFF'}
        >
          {section?.name}
        </Text>
        {active_department?.today_comment && (
          <Text
            style={{ textAlign: 'center', color: theme.primary || '#00A651' }}
            marginTop={scale(15)}
          >
            💬 {active_department.today_comment.comment}
          </Text>
        )}
        <Time size={scale(100)} />
        <Text uppercase size={scale(10)} semi style={styles.tap_to_start}>
          {t('START.TAP_TO_START')}
        </Text>
      </TouchableOpacity>
      <CompanyLogo center marginBottom={scale(25)} custom={theme.logo} />
      <RemoveApiKey />
    </DefaultContainer>
  );
};

export default StartScreen;
