import { View, Text } from "react-native";
import React, { useEffect, useMemo, useState } from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import CloseCross from "@/components/icons/CloseCross";
import DropdownArrow from "@/components/icons/DropdownArrow";
import { scale } from "react-native-size-matters";
import { setSection } from "@/redux/slices/clockings/functions";
import IShift from "@/types/IShift.model";

interface Props {
  sectionError: boolean;
  active_shift?: IShift;
  setSectionError: (value: React.SetStateAction<boolean>) => void;
  onSectionPress: () => void;
}

const SelectSectionButton: React.FC<Props> = ({
  sectionError,
  active_shift,
  setSectionError,
  onSectionPress,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { active_department, sections, theme } = useSelector(
    (state: AppState) => state.app
  );
  const { active_user, active_section } = useSelector(
    (state: AppState) => state.clockings
  );

  const show_sections = useMemo(() => {
    return (
      active_department?.params.freeclockin &&
      sections &&
      sections?.length > 0 &&
      ((!active_shift &&
        active_user?.sections &&
        active_user.sections.length > 0) ||
        (active_shift && !active_shift.section))
    );
  }, [active_department, active_shift, sections, active_user]);

  useEffect(() => {
    if (active_section) {
      setSectionError(false);
    }
  }, [active_section]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSectionError(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [sectionError]);

  const clearActiveSection = () => {
    dispatch(setSection(null));
  };

  if (!show_sections) return null;

  return (
    <Button
      value={active_section ? active_section.name : t("GLOBAL.SELECT_SECTION")}
      width={width / 2.5}
      icon={
        active_section ? (
          <Button
            value=""
            icon={
              <CloseCross color={sectionError ? theme.error! : "#FFFFFF"} />
            }
            onPress={clearActiveSection}
            no_style
          />
        ) : (
          <DropdownArrow color={sectionError ? theme.error! : undefined} />
        )
      }
      onPress={onSectionPress}
      justifyContent="space-between"
      marginTop={scale(10)}
      marginHorizontal={scale(5)}
      style={{
        borderColor: sectionError ? theme.error! : "#FFFFFF",
      }}
    />
  );
};

export default React.memo(SelectSectionButton);
