import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import Svg, { Rect } from 'react-native-svg';
import { useSelector } from 'react-redux';
import Text from '../../../components/ui/Text';

interface Props {
  message: string;
  icon: any;
  iconSide?: 'left' | 'right';
  ratio?: number;
  arrowSide: 'top' | 'bottom';
  marginTop?: number;
}

interface ArrowStyle {
  top?: number;
  bottom?: number;
}

const Tooltip: React.FC<Props> = ({
  message,
  icon,
  iconSide,
  ratio,
  arrowSide,
  marginTop,
}) => {
  const { theme } = useSelector((state: AppState) => state.app);

  const arrowStyle: ArrowStyle = {};
  arrowStyle[arrowSide] = arrowSide === 'top' ? scale(-10) : scale(-5);

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      backgroundColor: theme.contrast!,
      paddingVertical: scale(5),
      paddingHorizontal: scale(10),
      alignItems: 'center',
      borderRadius: 5,
    },
    arrow: {
      position: 'absolute',
      right: scale(50),
      zIndex: -1,
    },
  });

  return (
    <View
      style={[
        styles.container,
        { transform: [{ scale: ratio ? ratio : 1 }] },
        { marginTop: marginTop ? marginTop : 0 },
      ]}
    >
      {(!iconSide || iconSide === 'left') && icon}
      <Text
        size={scale(10)}
        marginLeft={!iconSide || iconSide === 'left' ? scale(5) : 0}
        marginRight={iconSide === 'right' ? scale(5) : 0}
        color={getContrast(theme.contrast!)}
      >
        {message}
      </Text>
      <View
        style={[
          styles.arrow,
          arrowStyle,
          { marginTop: marginTop ? marginTop : 0 },
        ]}
      >
        <Svg width={scale(10)} height={scale(10)} viewBox='0 0 33 33'>
          <Rect
            x='0.236572'
            y='16.5'
            width='23'
            height='23'
            transform='rotate(-45 0.236572 16.5)'
            fill={theme.contrast!}
          />
        </Svg>
      </View>
      {iconSide === 'right' && icon}
    </View>
  );
};

export default Tooltip;
