import CloseCross from '@/components/icons/CloseCross';
import Button from '@/components/ui/Button';
import {
  changeSection,
  setChangeSectionLoading,
} from '@/redux/slices/clockings/functions';
import { AppDispatch, AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import Text from '@components/ui/Text';
import { Camera } from 'expo-camera';
import * as ImageManipulator from 'expo-image-manipulator';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  camera: React.RefObject<Camera>;
}

const SelectedSectionBadge: React.FC<Props> = ({ camera }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { active_department } = useSelector((state: AppState) => state.app);
  const { active_clocking, active_shift, active_section, active_break } =
    useSelector((state: AppState) => state.clockings);

  const onClearSection = useCallback(async () => {
    try {
      dispatch(setChangeSectionLoading(true));
      // setLastInteraction(Date.now());
      let picture = null;
      if (active_department?.params.tablet_picture) {
        const image = await camera.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      dispatch(
        changeSection({
          clocking_id: active_clocking!.id,
          departmentId: active_department!.id,
          section_id: null,
          time: moment().unix().toString(),
          picture: picture ? picture?.base64 : undefined,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [active_clocking, active_department, camera]);

  const styles = useMemo(() => {
    return StyleSheet.create({
      badge: {
        paddingVertical: scale(2),
        borderRadius: 5,
        marginHorizontal: scale(5),
        flexDirection: 'row',
        alignItems: 'center',
      },
      badge_name: {
        paddingLeft: scale(10),
        paddingRight: active_shift ? scale(10) : 0,
      },
      badge_cross: {
        paddingLeft: scale(5),
        paddingRight: scale(5),
      },
      section_badge: {
        backgroundColor: active_section ? active_section.color! : 'transparent',
      },
    });
  }, [active_section, active_shift]);

  if (!active_section || active_break) return null;

  return (
    <View style={[styles.badge, styles.section_badge]}>
      <Text
        size={scale(10)}
        color={getContrast(
          active_section ? active_section.color! : 'transparent'
        )}
        style={styles.badge_name}
      >
        {active_section.name}
      </Text>
      {!active_shift ? (
        <View style={styles.badge_cross}>
          <Button
            onPress={onClearSection}
            element={
              <CloseCross
                color={
                  active_section.color
                    ? getContrast(active_section.color)
                    : null
                }
              />
            }
            no_style
          />
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(SelectedSectionBadge);
