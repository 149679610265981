import IAccount from '@/types/IAccount.model';
import IDepartment from '@/types/IDepartment.model';
import IResource from '@/types/IResource.model';
import ISkill from '@/types/ISkill.model';
import ITheme from '@/types/ITheme.model';
import IUser from '@/types/IUser.model';
import { getContrast, lighten } from '@/utils';
import { DATE_FORMAT, SHYFTER } from '@globals';
import ISection from '@models/ISection.model';
import ITask from '@models/ITask.model';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';

const CURRENT_DATE = moment().format(DATE_FORMAT);
const { primary, secondary, background, disabled, error } = SHYFTER.theme;

const initialState: IAppState = {
  api_key: null,
  section_id: null,
  theme: {
    logo: null,
    primary,
    secondary,
    background,
    contrast: getContrast(background),
    disabled,
    disabled_light: lighten(disabled, 50),
    error,
  },
  active_account: null,
  active_department: null,
  users: null,
  sections: null,
  tasks: null,
  resources: null,
  skills: null,
  date: CURRENT_DATE.charAt(0).toUpperCase() + CURRENT_DATE.slice(1),
  loading: true,
  error: false,
  message: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    registerTablet: (state, { payload }) => {
      state.api_key = payload.key;
      state.section_id = payload.section_id;
    },
    setTheme: (state, { payload }) => {
      const { background, disabled } = payload;

      state.theme = {
        ...payload,
        contrast: getContrast(background),
        disabled_light: lighten(disabled, 50),
      };
    },
    setDepartmentParams: (state, { payload }) => {
      state.active_department = {
        ...state.active_department,
        params: { ...state.active_department?.params, ...payload },
        modules: { ...state.active_department?.modules, ...payload },
      };
    },
    setActiveDepartment: (state, { payload }) => {
      state.active_department = {
        ...state.active_department,
        today_comment: payload,
      };
    },
    getPosData: (state, { payload }) => {
      const { background, disabled } = payload.theme;
      state.theme = {
        ...payload.theme,
        contrast: getContrast(background),
        disabled_light: lighten(disabled, 50),
      };
      state.active_account = payload.account;
      state.active_department = payload.department;
      state.users = payload.users.sort((a: IUser, b: IUser) =>
        a.displayName.localeCompare(b.displayName)
      );
      state.sections = payload.department.modules.sections
        ? payload.sections
        : null;
      state.skills = payload.department.modules.skills ? payload.skills : null;
      state.tasks = payload.department.modules.tasks ? payload.tasks : null;
      state.resources = payload.department.modules.resources
        ? payload.resources
        : null;
      const date = moment()
        .locale(payload.department.language)
        .format(DATE_FORMAT);
      state.date = date.charAt(0).toUpperCase() + date.slice(1);
    },
    getUser: (state, { payload }) => {
      state.users = state.users!.map((user) =>
        user.id !== payload.id ? user : { ...user, ...payload }
      );
    },
    updateUser: (state, { payload }) => {
      state.users = state.users!.map((user) =>
        user.id !== payload.id ? user : payload
      );
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = true;
      state.message = payload;
    },
    clearError: (state) => {
      state.error = false;
      state.message = null;
    },
    reset: (state) => {
      state.api_key = null;
      state.active_account = null;
      state.active_department = null;
      state.users = null;
      state.sections = null;
      state.skills = null;
      state.tasks = null;
      state.resources = null;
      state.loading = false;
      state.error = false;
      state.message = null;
    },
  },
});

export const actions = appSlice.actions;
export default appSlice.reducer;

interface IAppState {
  api_key?: string | null;
  section_id?: string | null;
  theme: ITheme;
  active_account: IAccount | null;
  active_department: IDepartment | null;
  users: IUser[] | null;
  sections: ISection[] | null;
  skills: ISkill[] | null;
  tasks: ITask[] | null;
  resources: IResource[] | null;
  date: string;
  loading: boolean;
  error: boolean;
  message: string | null;
}
