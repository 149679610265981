// import './wdyr';
import "./i18n.config";
import * as Sentry from "sentry-expo";
import {
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  useFonts,
} from "@expo-google-fonts/poppins";
import { NavigationContainer } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { Text } from "react-native";
import { Provider } from "react-redux";
import MainNavigator from "./navigators/MainNavigator";
import { store } from "./redux/store";

Sentry.init({
  dsn: "https://c47f0dd325a647d3b2bbb1960ff31796@o564498.ingest.sentry.io/4504474389446656",
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  autoSessionTracking: true,
});

const App: React.FC = () => {
  let [fontsLoaded] = useFonts({
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
  });

  if (!fontsLoaded) {
    return <Text>Loading...</Text>;
  }

  // console.log = () => null;

  return (
    <Provider store={store}>
      <StatusBar hidden />
      <NavigationContainer>
        <MainNavigator />
      </NavigationContainer>
    </Provider>
  );
};

export default App;
