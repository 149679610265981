import Close from "@/components/Close";
import { Feather } from "@expo/vector-icons";
import { ParamList } from "@globals";
import IUser from "@models/IUser.model";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { clearError } from "@redux/slices/app/functions";
import { AppDispatch, AppState } from "@redux/store";
import DefaultContainer from "@screens/default/DefaultContainer";
import Alphabet from "@screens/pin/components/Alphabet";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  InteractionManager,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import DialContainer from "./components/DialContainer";
import { FlashList } from "@shopify/flash-list";
import User from "./components/User";

const height = Dimensions.get("window").height;

const usersListRef = React.createRef<FlashList<IUser>>();

interface Props {}

const PinScreen: React.FC<Props> = () => {
  const navigation = useNavigation<NavigationProp<ParamList>>();
  const dispatch = useDispatch<AppDispatch>();
  const focused = useIsFocused();
  const { theme, users, error } = useSelector((state: AppState) => state.app);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    if (user && error) {
      dispatch(clearError());
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        dispatch(clearError());
      }, 2500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  const onGoBack = () => {
    navigation.goBack();
  };

  const renderUsers = useCallback(({ item }: { item: IUser }) => {
    return <User key={item.id} user={item} setUser={setUser} />;
  }, []);

  const keyExtractor = useCallback((item: IUser) => item.id, []);

  if (!focused) return null;

  return (
    <DefaultContainer justify="space-between">
      <TouchableOpacity style={styles.go_back_container} onPress={onGoBack}>
        <Feather name="arrow-left" size={scale(12)} color={theme.contrast!} />
      </TouchableOpacity>
      <View style={styles.main_container}>
        <View style={{ flex: 1 }}>
          <View style={styles.users_container}>
            <Alphabet marginRight={scale(10)} usersListRef={usersListRef} />
            <FlashList
              ref={usersListRef}
              data={users}
              renderItem={renderUsers}
              keyExtractor={keyExtractor}
              estimatedItemSize={75}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </View>
        <DialContainer user={user} />
      </View>
      <Close />
    </DefaultContainer>
  );
};

const styles = StyleSheet.create({
  go_back_container: {
    alignSelf: "flex-start",
    position: "absolute",
    top: scale(5),
    left: scale(5),
    padding: scale(15),
    zIndex: 1,
  },
  safe_area_container: {
    flexDirection: "row",
    flex: 1,
  },
  main_container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flex: 1,
    paddingHorizontal: scale(20),
  },
  users_container: {
    flexDirection: "row",
    flex: 1,
    maxHeight: Platform.OS !== "web" ? "auto" : height - 200,
    marginTop: Platform.OS !== "web" ? scale(45) : 0,
  },
  dots: {
    flexDirection: "row",
    marginTop: scale(15),
    alignItems: "center",
  },
  clear_arrow: {
    marginLeft: scale(10),
  },
  dial_container: {
    marginTop: scale(15),
  },
  spinner_container: {
    flex: 1,
    justifyContent: "center",
  },
});

export default PinScreen;
