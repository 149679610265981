import { View, Text } from "react-native";
import React, { useMemo } from "react";
import Button from "@/components/ui/Button";
import CloseCross from "@/components/icons/CloseCross";
import DropdownArrow from "@/components/icons/DropdownArrow";
import { scale } from "react-native-size-matters";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import { setAttribute } from "@/redux/slices/clockings/functions";
import IShift from "@/types/IShift.model";

interface Props {
  active_shift?: IShift;
  onAttributePress: () => void;
}

const SelectAttributeButton: React.FC<Props> = ({
  active_shift,
  onAttributePress,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { active_department, resources } = useSelector(
    (state: AppState) => state.app
  );
  const { active_attribute } = useSelector(
    (state: AppState) => state.clockings
  );

  const show_attributes = useMemo(() => {
    return (
      active_department?.params.freeclockin &&
      !active_shift &&
      resources &&
      resources?.length
    );
  }, [active_department, active_shift, resources]);

  const clearActiveAttribute = () => {
    dispatch(setAttribute(null));
  };

  if (!show_attributes) return null;

  return (
    <Button
      value={
        active_attribute ? active_attribute.name : t("GLOBAL.SELECT_ATTRIBUTE")
      }
      width={width / 2.5}
      icon={
        active_attribute ? (
          <Button
            value=""
            icon={<CloseCross color="#FFFFFF" />}
            onPress={clearActiveAttribute}
            no_style
          />
        ) : (
          <DropdownArrow />
        )
      }
      onPress={onAttributePress}
      justifyContent="space-between"
      marginTop={scale(10)}
      marginHorizontal={scale(5)}
    />
  );
};

export default SelectAttributeButton;
