import Button from "@/components/ui/Button";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  setModalSelectSectionVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeSectionButton: React.FC<Props> = ({
  setModalSelectSectionVisible,
}) => {
  const { t } = useTranslation();
  const { sections, theme } = useSelector((state: AppState) => state.app);
  const { active_user, active_shift, change_section_loading } = useSelector(
    (state: AppState) => state.clockings
  );

  const onSectionPress = () => {
    //   setLastInteraction(Date.now());
    setModalSelectSectionVisible(true);
  };

  const show_sections = useMemo(() => {
    return (
      sections &&
      sections.length > 0 &&
      ((!active_shift &&
        active_user?.sections &&
        active_user.sections.length > 0) ||
        (active_shift && !active_shift.section))
    );
  }, [active_shift, sections, active_user]);

  if (!show_sections) return null;

  return (
    <Button
      value={t("GLOBAL.SECTION")}
      color={getContrast(theme.background!)}
      textColor={getContrast(getContrast(theme.background!))}
      onPress={onSectionPress}
      style={{
        flex: 1,
        marginRight: scale(2),
        marginTop: scale(5),
      }}
      loading={change_section_loading}
    />
  );
};

export default React.memo(ChangeSectionButton);
