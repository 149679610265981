import { AppState } from '@/redux/store';
import { lighten } from '@/utils';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Circle, Rect } from 'react-native-svg';
import { useSelector } from 'react-redux';

interface Props {
  withCircle?: boolean;
}

const BackgroundShapes: React.FC<Props> = ({ withCircle }) => {
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <View style={[StyleSheet.absoluteFill, { flex: 1 }]}>
      <Svg height='100%' width='100%' viewBox='0 0 1440 960'>
        <Rect
          x='600'
          y='-750'
          width='1000'
          height='1000'
          fill={lighten(theme.background!, 15)}
          rx='80'
          rotation={-67}
          originX='500'
          originY='500'
        />
        {withCircle ? (
          <React.Fragment>
            <Circle
              cx='720'
              cy='480'
              r='300'
              fill={lighten(theme.background!, 15)}
            />
            <Circle
              cx='720'
              cy='480'
              r='230'
              fill={lighten(theme.background!, 25)}
            />
          </React.Fragment>
        ) : null}
        <Rect
          x='-500'
          y='1250'
          width='1000'
          height='1000'
          fill={lighten(theme.background!, 15)}
          rx='80'
          rotation={-67}
          originX='500'
          originY='500'
        />
      </Svg>
    </View>
  );
};

export default BackgroundShapes;
