import ISkill from "@/types/ISkill.model";
import { AppState } from "@redux/store";
import Text from "@ui/Text";
import { Camera } from "expo-camera";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import SkillItem from "./SkillItem";
import { FlashList } from "@shopify/flash-list";
import IShift from "@/types/IShift.model";

interface Props {
  from: "home" | "profile";
  visible: boolean;
  active_shift?: IShift | null;
  setVisible: (visible: boolean) => void;
  camera: React.RefObject<Camera>;
}

const ModalSelectSkill: React.FC<Props> = ({
  from,
  visible,
  camera,
  active_shift,
  setVisible,
}) => {
  const { t } = useTranslation();
  const { width, height } = useSelector((state: AppState) => state.device);
  const { active_user } = useSelector((state: AppState) => state.clockings);
  const { theme, skills } = useSelector((state: AppState) => state.app);

  const allowed_skills = useMemo(() => {
    return skills?.filter((skill) => active_user?.skills?.includes(skill.id));
  }, [skills, active_user?.skills]);

  const onBackdropPress = useCallback(() => {
    return setVisible(false);
  }, []);

  const renderSkills = useCallback(({ item }: { item: ISkill }) => {
    return (
      <SkillItem
        from={from}
        skill={item}
        setModalVisible={setVisible}
        camera={camera}
      />
    );
  }, []);

  const keyExtractor = useCallback((item: ISkill) => item.id, []);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.secondary!,
      padding: scale(15),
      borderRadius: 5,
      width: width / 2,
      alignSelf: "center",
      maxHeight: height / 1.2,
    },
  });

  if (!visible) return null;

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <View style={styles.container}>
        <Text size={scale(10)} bold color={theme.primary!}>
          {t("GLOBAL.SELECT_SKILL")}
        </Text>
        <SkillItem
          from={from}
          skill={null}
          setModalVisible={setVisible}
          camera={camera}
        />
        <FlatList
          data={active_shift ? active_shift.skills : allowed_skills}
          renderItem={renderSkills}
          keyExtractor={keyExtractor}
          showsVerticalScrollIndicator={false}
          initialNumToRender={allowed_skills?.length}
        />
      </View>
    </Modal>
  );
};

export default ModalSelectSkill;
