import Clear from "@/components/icons/Clear";
import { ParamList } from "@/globals";
import { getUser, setError } from "@/redux/slices/app/functions";
import {
  setBreak,
  setClocking,
  setUser,
} from "@/redux/slices/clockings/functions";
import { AppDispatch, AppState } from "@/redux/store";
import IUser from "@/types/IUser.model";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import Text from "@ui/Text";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import Dial from "./Dial";
import PinCodeLength from "./PinCodeLength";

interface Props {
  user: IUser | null;
}

const DialContainer: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const dispatch = useDispatch<AppDispatch>();
  const { theme, active_department, error, message } = useSelector(
    (state: AppState) => state.app
  );
  const { active_clocking } = useSelector((state: AppState) => state.clockings);
  const { loading } = useSelector((state: AppState) => state.clockings);
  const [pin_code, setPinCode] = useState<string>("");

  useEffect(() => {
    if (pin_code.length === 4 && user) {
      verifyPin();
    }
  }, [pin_code]);

  const verifyPin = useCallback(async () => {
    if (user) {
      const { pin, clocking } = user;

      if (pin !== pin_code) {
        setPinCode("");
        await dispatch(setError(t("PIN.WRONG_PIN")));
      } else {
        await dispatch(setUser(user));
        await dispatch(getUser(user, active_department!.id, navigation));
        if (active_clocking) {
          const { breaks } = active_clocking || {};
          if (breaks && breaks.length > 0) {
            const last_break = breaks[breaks.length - 1];
            if (last_break && !last_break.enddate) {
              dispatch(setBreak(last_break));
            }
          }
        }
      }
    }
  }, [user, pin_code]);

  const onClearPin = useCallback(() => {
    if (pin_code.length > 0 && !loading) {
      setPinCode(pin_code.substring(0, pin_code.length - 1));
    }
  }, [pin_code]);

  return (
    <View
      style={{
        alignItems: "center",
        opacity: user ? 1 : 0.3,
        flex: 0.6,
      }}
      pointerEvents={user ? "auto" : "none"}
    >
      {user ? (
        <Text size={scale(15)} uppercase bold center>
          {user.displayName}
        </Text>
      ) : null}
      <Text size={scale(10)} uppercase semi center>
        {t("PIN.ENTER_YOUR_PIN")}
      </Text>
      <View style={styles.dots}>
        <PinCodeLength pin_code={pin_code} />
        {!loading ? (
          <TouchableOpacity style={styles.clear_arrow} onPress={onClearPin}>
            <Clear />
          </TouchableOpacity>
        ) : (
          <ActivityIndicator color={theme.primary!} size="small" />
        )}
      </View>
      {error ? (
        <Text error marginTop={scale(10)}>
          {message}
        </Text>
      ) : null}
      <View style={styles.dial_container}>
        <Dial setCode={setPinCode} code={pin_code} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  go_back_container: {
    alignSelf: "flex-start",
    position: "absolute",
    top: 0,
    left: 0,
    padding: scale(20),
    zIndex: 1,
  },
  safe_area_container: {
    flexDirection: "row",
    flex: 1,
  },
  main_container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: scale(20),
  },
  users_inner_container: {
    marginRight: scale(20),
    paddingTop: scale(30),
  },
  users_container: {
    marginVertical: scale(15),
    flexDirection: "row",
    flex: 1,
  },
  dots: {
    flexDirection: "row",
    marginTop: scale(15),
    alignItems: "center",
  },
  clear_arrow: {
    marginLeft: scale(10),
  },
  dial_container: {
    marginTop: scale(15),
  },
});

export default React.memo(DialContainer);
