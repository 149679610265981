import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import React from 'react';
import { scale } from 'react-native-size-matters';
import Svg, { Path } from 'react-native-svg';
import { useSelector } from 'react-redux';

interface Props {
  color?: string;
}

const Pause: React.FC<Props> = ({ color }) => {
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <Svg width={scale(10)} height={scale(10)} viewBox='0 0 30 29' fill='none'>
      <Path
        d='M10.2981 1.44678C9.8785 2.74969 9.66543 4.11025 9.66667 5.47907C9.66667 12.7277 15.543 18.6041 22.7917 18.6041C24.6401 18.6041 26.3989 18.222 27.9935 17.5322C26.2924 22.8092 21.3421 26.6249 15.5 26.6249C8.25135 26.6249 2.375 20.7485 2.375 13.4999C2.375 8.09896 5.63656 3.46074 10.2981 1.44678V1.44678Z'
        stroke={color ? color : getContrast(theme.contrast!)}
        strokeWidth='2.91667'
        strokeLinejoin='round'
      />
      <Path
        d='M21.0854 3.29199H27.8958L20.6042 9.12533H27.8958'
        stroke={color ? color : getContrast(theme.contrast!)}
        strokeWidth='2.91667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default Pause;
