import Text from '@/components/ui/Text';
import IShift from '@/types/IShift.model';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';

interface Props {
  active_shift: IShift;
}

const TasksList: React.FC<Props> = ({ active_shift }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        {active_shift.tasks.map((task) => (
          <Text key={task.id}>{task.name}</Text>
        ))}
      </View>
      <View style={styles.right}>
        {active_shift.tasks.map((task) => (
          <Text key={task.id}>
            {moment.unix(task.startdate).format('HH:mm')} -{' '}
            {task.enddate ? moment.unix(task.enddate).format('HH:mm') : '__:__'}
          </Text>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: scale(15),
    flexDirection: 'row',
  },
  left: {},
  right: {
    marginLeft: scale(25),
    alignItems: 'flex-end',
  },
});

export default TasksList;
