import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { scale } from "react-native-size-matters";
import Text from "@ui/Text";
import { useTranslation } from "react-i18next";
import { SHYFTER } from "@/globals";
import Constants from "expo-constants";

interface Props {}

const NoApiKey: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <View>
      <Text white center size={scale(7)} color="#E3E6EE">
        {t("API.NO_API_KEY")}
      </Text>
      <TouchableOpacity>
        <Text white center size={scale(7)} color="#E3E6EE">
          {t("API.CONTACT_US")}{" "}
          <Text size={scale(7)} color="#E3E6EE" underline>
            {SHYFTER.support.email}
          </Text>
        </Text>
      </TouchableOpacity>
      <Text white center size={scale(7)}>
        Connected to: {Constants.expoConfig?.extra?.API_URL}
      </Text>
    </View>
  );
};

export default NoApiKey;
