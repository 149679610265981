import React from 'react';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';

interface Props {}

const Separator: React.FC<Props> = () => {
  return <View style={styles.container}></View>;
};

const styles = StyleSheet.create({
  container: {
    height: scale(1),
    backgroundColor: '#F5FEF8',
    opacity: 0.3,
    width: '100%',
  },
});

export default Separator;
