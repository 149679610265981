import { AppState } from "@/redux/store";
import ShapesBackground from "@components/ShapesBackground";
import React from "react";
import { KeyboardAvoidingView, Platform, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

interface Props {
  children: React.ReactNode;
  justify?: "center" | "space-between" | "space-around";
  align?: "center" | "flex-start" | "flex-end";
  withCircle?: boolean;
}

const DefaultContainer: React.FC<Props> = ({
  children,
  justify,
  align,
  withCircle,
}) => {
  const { theme } = useSelector((state: AppState) => state.app);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.background!,
      color: "#fff",
      flex: 1,
    },
  });

  return (
    <>
      {Platform.OS !== "web" ? (
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={[
            styles.container,
            {
              justifyContent: justify ? justify : "center",
              alignItems: align ? align : "center",
            },
          ]}
        >
          <ShapesBackground withCircle={withCircle} />
          {children}
        </KeyboardAvoidingView>
      ) : (
        <View
          style={{
            backgroundColor: theme.background!,
            justifyContent: justify ? justify : "center",
            alignItems: align ? align : "center",
            flex: 1,
          }}
        >
          <ShapesBackground withCircle={withCircle} />
          {children}
        </View>
      )}
    </>
  );
};

export default React.memo(DefaultContainer);
