import React from "react";
import { AutoFocus, Camera, CameraType, FlashMode } from "expo-camera";
import { StyleSheet } from "react-native";

const MemoizedCamera = React.memo(Camera);
const cameraStyle = [StyleSheet.absoluteFillObject, { opacity: 0 }];

const CameraWrapper = React.forwardRef((props, ref) => {
  return (
    <MemoizedCamera
      ref={ref as any}
      type={CameraType.front}
      flashMode={FlashMode.off}
      autoFocus={AutoFocus.off}
      style={cameraStyle}
      useCamera2Api
    />
  );
});

export default CameraWrapper;
