import { configureStore } from '@reduxjs/toolkit';
import deviceReducer from '@redux/slices/device/deviceSlice';
import appReducer from '@redux/slices/app/appSlice';
import clockingsReducer from '@redux/slices/clockings/clockingsSlice';

export const store = configureStore({
  reducer: {
    device: deviceReducer,
    app: appReducer,
    clockings: clockingsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
