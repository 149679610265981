import { ParamList, SHYFTER } from "@/globals";
import { reset } from "@/redux/slices/app/functions";
import { AppDispatch, AppState } from "@/redux/store";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Text from "@ui/Text";
import { getContrast, lighten } from "@/utils";
import Constants from "expo-constants";

interface Props {}

const LONG_PRESS_DELAY = 3000;

const RemoveApiKey: React.FC<Props> = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const dispatch = useDispatch<AppDispatch>();
  const { theme } = useSelector((state: AppState) => state.app);

  const onLongPress = () => {
    dispatch(reset());
    navigation.navigate("Api");
  };

  const styles = StyleSheet.create({
    container: {
      position: "absolute",
      bottom: 0,
      left: 3,
    },
    version: {
      fontSize: 12,
      paddingTop: 15,
      paddingRight: 10,
      color: lighten(getContrast(theme.background!), -100),
    },
  });

  return (
    <TouchableOpacity
      style={styles.container}
      onLongPress={onLongPress}
      delayLongPress={LONG_PRESS_DELAY}
    >
      <Text style={styles.version}>v{Constants.expoConfig?.version}</Text>
    </TouchableOpacity>
  );
};

export default RemoveApiKey;
