import TaskItem from '@/screens/home/components/tasks/TaskItem';
import ITask from '@models/ITask.model';
import { AppState } from '@redux/store';
import Text from '@ui/Text';
import { Camera } from 'expo-camera';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';
import ShiftTasksList from './ShiftTasksList';

interface Props {
  from: 'home' | 'profile';
  visible: boolean;
  setVisible: (visible: boolean) => void;
  camera: React.RefObject<Camera>;
}

const ModalSelectTask: React.FC<Props> = ({
  from,
  visible,
  camera,
  setVisible,
}) => {
  const { t } = useTranslation();
  const { width, height } = useSelector((state: AppState) => state.device);
  const { theme, tasks } = useSelector((state: AppState) => state.app);
  const { active_shift } = useSelector((state: AppState) => state.clockings);

  const onBackdropPress = useCallback(() => {
    return setVisible(false);
  }, []);

  const renderTasks = useCallback(({ item }: { item: ITask }) => {
    return (
      <TaskItem
        from={from}
        task={item}
        setModalVisible={setVisible}
        camera={camera!}
      />
    );
  }, []);

  const keyExtractor = useCallback((item: ITask) => item.id, []);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.secondary!,
      padding: scale(15),
      borderRadius: 5,
      width: width / 2,
      alignSelf: 'center',
      maxHeight: height / 1.2,
    },
  });

  if (!visible) return null;

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <View style={styles.container}>
        {active_shift && (
          <ShiftTasksList camera={camera} setModalVisible={setVisible} />
        )}
        <Text size={scale(10)} bold color={theme.primary!}>
          {t('GLOBAL.SELECT_TASK')}
        </Text>
        <TaskItem
          from={from}
          task={null}
          setModalVisible={setVisible}
          camera={camera!}
        />
        <FlatList
          data={tasks}
          renderItem={renderTasks}
          keyExtractor={keyExtractor}
          showsVerticalScrollIndicator={false}
          initialNumToRender={tasks?.length}
        />
      </View>
    </Modal>
  );
};

export default React.memo(ModalSelectTask);
