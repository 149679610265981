import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import React from 'react';
import { Text, TextInput, TextProps, TextStyle, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';

interface Props extends TextProps {
  area?: boolean;
  numberOfLines?: number;
  value?: string | null;
  onChangeText?: (text: string) => void;
  fontFamily?: string;
  white?: boolean;
  center?: boolean;
  left?: boolean;
  size?: number;
  color?: string;
  underline?: boolean;
  uppercase?: boolean;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  error?: boolean;
  bold?: boolean;
  semi?: boolean;
  width?: number | string;
  secondary?: boolean;
  loading?: boolean;
}

const DefaultText: React.FC<Props> = (props) => {
  const { theme } = useSelector((state: AppState) => state.app);

  const styles: TextStyle = {
    fontFamily: props.bold
      ? 'Poppins_700Bold'
      : props.semi
      ? 'Poppins_600SemiBold'
      : props.fontFamily
      ? props.fontFamily
      : 'Poppins_400Regular',
    color: props.loading
      ? '#b1b1b1'
      : props.color
      ? props.color
      : props.secondary
      ? '#273140'
      : props.error
      ? theme.error!
      : props.white
      ? '#fff'
      : theme.contrast!,
    textAlign: props.center ? 'center' : 'auto',
    fontSize: props.size ? props.size : scale(10),
    textDecorationLine: props.underline ? 'underline' : 'none',
    textTransform: props.uppercase ? 'uppercase' : 'none',
    marginTop: props.marginTop ? props.marginTop : 0,
    marginBottom: props.marginBottom ? props.marginBottom : 0,
    marginLeft: props.marginLeft ? props.marginLeft : 0,
    marginRight: props.marginRight ? props.marginRight : 0,
    width: props.width ? props.width : 'auto',
    alignSelf: props.center ? 'center' : props.left ? 'flex-start' : 'auto',
  };

  return (
    <>
      {props.area ? (
        <View
          style={{
            borderColor: props.color,
            borderWidth: 2,
            borderRadius: 5,
          }}
        >
          <TextInput
            {...props}
            multiline
            numberOfLines={props.numberOfLines}
            style={[
              styles,
              props.style,
              { color: getContrast(theme.secondary!) },
            ]}
            underlineColorAndroid='transparent'
            value={props.value ? props.value : ''}
            onChangeText={props.onChangeText}
          />
        </View>
      ) : (
        <Text {...props} style={[styles, props.style]}>
          {props.children}
        </Text>
      )}
    </>
  );
};

export default DefaultText;
