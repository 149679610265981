import { View, Text, Platform } from "react-native";
import React, { useCallback, useMemo } from "react";
import Button from "@/components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import { scale } from "react-native-size-matters";
import { Camera } from "expo-camera";
import {
  clockingIn,
  setClockLoading,
} from "@/redux/slices/clockings/functions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ParamList } from "@/globals";
import { useTranslation } from "react-i18next";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as FileSystem from "expo-file-system";
import * as Network from "expo-network";
import * as ImageManipulator from "expo-image-manipulator";
import IShift from "@/types/IShift.model";

interface Props extends NativeStackScreenProps<ParamList, "Home"> {
  camera: React.RefObject<Camera>;
  active_shift: IShift | undefined;
  setSectionError: (value: React.SetStateAction<boolean>) => void;
}

const StartButton: React.FC<Props> = ({
  camera,
  navigation,
  active_shift,
  setSectionError,
}) => {
  const { t } = useTranslation();
  const { active_department, sections, section_id } = useSelector(
    (state: AppState) => state.app
  );
  const {
    active_user,
    active_section,
    active_skill,
    active_attribute,
    active_task,
    clock_loading,
  } = useSelector((state: AppState) => state.clockings);
  const dispatch = useDispatch<AppDispatch>();

  const is_shift = active_shift?.shift_type == 1;

  const show_start = useMemo(() => {
    return (!active_shift && active_department?.params.freeclockin) || is_shift;
  }, [active_shift, active_department, is_shift]);

  const onStart = useCallback(async () => {
    try {
      await dispatch(setClockLoading(true));
      let picture = null;
      let sectionError = false;

      if (
        active_department?.modules.sections &&
        sections &&
        sections.length > 0 &&
        active_user &&
        active_user.sections &&
        active_user.sections.length > 0
      ) {
        if (!active_shift && !active_section && !section_id) {
          sectionError = true;
        }
      }

      if (active_department?.params.tablet_picture) {
        const image = await camera.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      if (sectionError) {
        setSectionError(true);
        dispatch(setClockLoading(false));
        return;
      }

      const network = await Network.getNetworkStateAsync();
      const { granted } = await Camera.getCameraPermissionsAsync();

      await dispatch(
        clockingIn({
          departmentId: active_department!.id,
          user_id: active_user!.id,
          shift_id: active_shift ? active_shift.id : "0",
          start: moment().unix().toString(),
          section_id: section_id
            ? section_id
            : active_section
            ? active_section.id
            : undefined,
          skill_id: active_skill ? active_skill.id : undefined,
          task_id: active_task ? active_task.id : undefined,
          attribute_id: active_attribute ? active_attribute.id : undefined,
          picture: picture ? picture?.base64 : undefined,
          infos: {
            version: Constants.expoConfig?.version,
            os: Platform.OS,
            model_name: Device.modelName,
            has_internet: network.isConnected,
            internet_reachable: network.isInternetReachable,
            camera_granted: granted,
            notifications: false,
            total_disk_space:
              Platform.OS !== "web"
                ? `${await FileSystem.getTotalDiskCapacityAsync()}`
                : null,
            free_disk_space:
              Platform.OS !== "web"
                ? `${await FileSystem.getFreeDiskStorageAsync()}`
                : null,
            ram: Device.totalMemory ? `${Device.totalMemory}` : null,
          },
        })
      );

      navigation.navigate("Message", {
        tooltip: t("MESSAGE.TOOLTIPS.DONT_FORGET_CHECKOUT"),
        message: t("MESSAGE.MESSAGES.YOU_JUST_CHECKED_IN"),
        icon_name: "pause",
        from: "home",
        stay: true,
      });
    } catch (err) {
      console.log(err);
    }
  }, [
    active_department,
    sections,
    active_user,
    active_shift,
    active_section,
    active_skill,
    active_task,
    active_attribute,
  ]);

  if (!show_start) return null;

  return (
    <Button
      value={
        active_shift ? t("HOME.START_CLOCKING") : t("HOME.START_FREE_CLOCKING")
      }
      onPress={onStart}
      primary
      marginTop={scale(15)}
      loading={clock_loading}
      disabled={!active_shift && !active_department?.params.freeclockin}
    />
  );
};

export default React.memo(StartButton);
