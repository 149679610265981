import { View, Text } from "react-native";
import React from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import { scale } from "react-native-size-matters";

interface Props {
  setModalClockingDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowClockingDetailsButton: React.FC<Props> = ({
  setModalClockingDetailsVisible,
}) => {
  const { t } = useTranslation();
  const { active_department, theme } = useSelector(
    (state: AppState) => state.app
  );

  const onClockingDetails = () => {
    //   setLastInteraction(Date.now());
    setModalClockingDetailsVisible(true);
  };

  const show_button = active_department?.params.clocking_details;

  if (!show_button) return null;

  return (
    <Button
      value={t("PROFILE.CLOCKING_DETAILS")}
      small
      onPress={onClockingDetails}
      textColor={getContrast(theme.background!)}
      style={{ marginRight: scale(10) }}
    />
  );
};

export default React.memo(ShowClockingDetailsButton);
