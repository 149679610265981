import React, { Dispatch, SetStateAction, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import Svg, { Circle } from 'react-native-svg';
import Text from '@ui/Text';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';

interface Props {
  value?: number | string | undefined;
  icon?: any;
  setCode: Dispatch<SetStateAction<string>>;
  code: string;
}

const Digit: React.FC<Props> = ({ value, icon, setCode, code }) => {
  const { theme } = useSelector((state: AppState) => state.app);
  const [active, setActive] = useState<boolean>(false);
  const onPressIn = () => {
    setActive(true);
    setCode(code + value);
  };

  const onPressOut = () => {
    setActive(false);
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={styles.container}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
    >
      <Svg width={scale(35)} height={scale(35)} viewBox='0 0 94 94' fill='none'>
        <Circle
          cx='47'
          cy='47'
          r='45.5'
          fill={active ? theme.contrast! : '#29513C'}
          fillOpacity={active ? 1 : 0.3}
          stroke={theme.contrast!}
          strokeWidth={3}
        />
      </Svg>
      <Text
        color={active ? theme.primary! : theme.contrast!}
        style={styles.text}
        size={scale(code != 'clear' ? 15 : 10)}
      >
        {value}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: scale(10),
    paddingVertical: scale(5),
  },
  text: {
    position: 'absolute',
  },
});

export default Digit;
