import { store } from '@/redux/store';
import IAttribute from '@/types/IAttribute.model';
import { IBreak } from '@/types/IBreak.model';
import ISection from '@/types/ISection.model';
import IShift from '@/types/IShift.model';
import ISkill from '@/types/ISkill.model';
import ITask from '@/types/ITask.model';
import IUser from '@/types/IUser.model';
import IBreakInRequest from '@/types/requests/IBreakIn.model';
import IBreakOutRequest from '@/types/requests/IBreakOut.model';
import { IChangeAttributeRequest } from '@/types/requests/IChangeAttribute.model';
import { IChangeSectionRequest } from '@/types/requests/IChangeSection.model';
import { IChangeSkillRequest } from '@/types/requests/IChangeSkill.model';
import { IChangeTaskRequest } from '@/types/requests/IChangeTask.model';
import { IClockingOutRequest } from '@/types/requests/IClockingOut.model';
import { ISignDocumentRequest } from '@/types/requests/ISignDocument.model';
import api from '@api';
import { IClocking } from '@models/IClocking.model';
import IClockingInRequest from '@models/requests/IClockingIn.model';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { actions } from './clockingsSlice';

type Dispatch = typeof store.dispatch;

// Clocking in
export const clockingIn =
  (values: IClockingInRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setClockLoading(true));
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) {
          if (key == 'infos') {
            const infos = values[key];
            if (!infos) continue;
            const keys = Object.keys(infos).map((key) => `infos[${key}]`);
            const results = Object.values(infos);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = results[i];
              if (!value) continue;
              formData.append(key, value);
            }
          } else {
            formData.append(key, (values as any)[key]);
          }
        }
      }
      formData.append('os', Platform.OS);

      const { data }: { data: IClocking } = await api.post(
        '/clocking-in',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      dispatch(actions.clockingIn(data));
      dispatch(actions.setClockLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Clocking out
export const clockingOut =
  (values: IClockingOutRequest) => async (dispatch: Dispatch) => {
    try {
      await dispatch(actions.setClockLoading(true));
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) {
          if (key == 'infos') {
            const infos = values[key];
            if (!infos) continue;
            const keys = Object.keys(infos).map((key) => `infos[${key}]`);
            const results = Object.values(infos);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = results[i];
              if (!value) continue;
              formData.append(key, value);
            }
          } else {
            formData.append(key, (values as any)[key]);
          }
        }
      }
      formData.append('os', Platform.OS);

      await api.post('/clocking-out', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await dispatch(actions.clockingOut());
      await dispatch(actions.setClockLoading(false));
      await dispatch(actions.reset());
    } catch (err) {
      throw err;
    }
  };

// Break in
export const breakIn =
  (values: IBreakInRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setBreakLoading(true));
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) {
          if (key == 'infos') {
            const infos = values[key];
            if (!infos) continue;
            const keys = Object.keys(infos).map((key) => `infos[${key}]`);
            const results = Object.values(infos);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = results[i];
              if (!value) continue;
              formData.append(key, value);
            }
          } else {
            formData.append(key, (values as any)[key]);
          }
        }
      }

      const { data }: { data: IClocking } = await api.post(
        '/break-in',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      dispatch(actions.breakIn(data));
      dispatch(actions.setBreakLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Break out
export const breakOut =
  (values: IBreakOutRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setBreakLoading(true));
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) {
          if (key == 'infos') {
            const infos = values[key];
            if (!infos) continue;
            const keys = Object.keys(infos).map((key) => `infos[${key}]`);
            const results = Object.values(infos);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = results[i];
              if (!value) continue;
              formData.append(key, value);
            }
          } else {
            formData.append(key, (values as any)[key]);
          }
        }
      }

      const { data }: { data: IClocking } = await api.post(
        '/break-out',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      dispatch(actions.breakOut(data));
      dispatch(actions.setBreakLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Change section
export const changeSection =
  (values: IChangeSectionRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeSectionLoading(true));

      const { data }: { data: IClocking } = await api.post(
        '/change-section',
        values
      );

      dispatch(actions.changeSection(data));
      dispatch(actions.setChangeSectionLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Change skill
export const changeSkill =
  (values: IChangeSkillRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeSkillLoading(true));

      const { data }: { data: IClocking } = await api.post(
        '/change-skill',
        values
      );

      dispatch(actions.changeSkill(data));
      dispatch(actions.setChangeSkillLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Change task
export const changeTask =
  (values: IChangeTaskRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeTaskLoading(true));
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) {
          formData.append(key, (values as any)[key]);
        }
      }

      const { data }: { data: IClocking } = await api.post(
        '/change-task',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      dispatch(actions.changeTask(data));
      dispatch(actions.setChangeTaskLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Change attribute
export const changeAttribute =
  (values: IChangeAttributeRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeAttributeLoading(true));

      const { data }: { data: IClocking } = await api.post(
        '/change-attribute',
        values
      );

      dispatch(actions.changeAttribute(data));
      dispatch(actions.setChangeAttributeLoading(false));
    } catch (err) {
      throw err;
    }
  };

// Sign document
export const signDocument =
  (values: ISignDocumentRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setSignDocumentLoading(true));

      const { data }: { data: IClocking } = await api.post(
        `/sign-document/${values['documentType']}/${values['documentId']}`,
        values
      );

      dispatch(actions.setSignDocumentLoading(true));
    } catch (err) {
      console.log(err);

      throw err;
    }
  };

// Set active user
export const setUser = (user: IUser | null) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setUser(user));
    if (user) {
      if (Platform.OS !== 'web') {
        Sentry.Native.setUser({
          record_id: user?.id,
          main_id: user.main_id,
          email: user.email,
          username: user.displayName,
          pin: user.pin,
          clocking: user.clocking ? user.clocking.id : null,
          contract_to_sign: user.contract_to_sign
            ? user.contract_to_sign.id
            : null,
          today_shifts: user.today_shifts,
        });
      } else {
        Sentry.Browser.setUser({
          record_id: user?.id,
          main_id: user.main_id,
          email: user.email,
          username: user.displayName,
          pin: user.pin,
          clocking: user.clocking ? user.clocking.id : null,
          contract_to_sign: user.contract_to_sign
            ? user.contract_to_sign.id
            : null,
          today_shifts: user.today_shifts,
        });
      }
    } else {
      if (Platform.OS !== 'web') {
        Sentry.Native.setUser(null);
      } else {
        Sentry.Browser.setUser(null);
      }
    }
  } catch (err) {
    throw err;
  }
};

// Set clocking
export const setClocking =
  (clocking: IClocking | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setClocking(clocking));
    } catch (err) {
      throw err;
    }
  };

// Set active shift
export const setShift =
  (shift: IShift | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setShift(shift));
    } catch (err) {
      throw err;
    }
  };

// Set section
export const setSection =
  (section: ISection | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setSection(section));
    } catch (err) {
      throw err;
    }
  };

// Set task
export const setTask = (task: ITask | null) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setTask(task));
  } catch (err) {
    throw err;
  }
};

// Set skill
export const setSkill =
  (skill: ISkill | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setSkill(skill));
    } catch (err) {
      throw err;
    }
  };

// Set attribute
export const setAttribute =
  (attribute: IAttribute | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setAttribute(attribute));
    } catch (err) {
      throw err;
    }
  };

// Set break
export const setBreak =
  (_break: IBreak | null) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setBreak(_break));
    } catch (err) {
      throw err;
    }
  };

// Set clock-in/out loading
export const setClockLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setClockLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set break-in/out loading
export const setBreakLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setBreakLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set change section loading
export const setChangeSectionLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeSectionLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set change section loading
export const setChangeSkillLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeSkillLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set change task loading
export const setChangeTaskLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeTaskLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set change attribute loading
export const setChangeAttributeLoading =
  (loading: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setChangeAttributeLoading(loading));
    } catch (err) {
      throw err;
    }
  };

// Set loading
export const setLoading = (loading: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setLoading(loading));
  } catch (err) {
    throw err;
  }
};

// Reset
export const reset = () => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.reset());
  } catch (err) {
    throw err;
  }
};
