import React from "react";
import { scale } from "react-native-size-matters";
import Svg, { Path } from "react-native-svg";

interface Props {}

const Attention: React.FC<Props> = () => {
  return (
    <Svg width={scale(10)} height={scale(10)} viewBox="0 0 27 25" fill="none">
      <Path
        d="M25.95 20.9076L15.5325 1.73756C15.3251 1.35551 15.0184 1.03651 14.6449 0.814227C14.2713 0.591942 13.8447 0.474609 13.41 0.474609C12.9753 0.474609 12.5486 0.591942 12.175 0.814227C11.8015 1.03651 11.4948 1.35551 11.2875 1.73756L0.877458 20.9076C0.671601 21.2762 0.565971 21.6923 0.571101 22.1145C0.576232 22.5366 0.691944 22.95 0.906699 23.3135C1.12145 23.677 1.42775 23.9779 1.79504 24.1861C2.16233 24.3942 2.57778 24.5025 2.99996 24.5001H23.8275C24.2453 24.4996 24.656 24.3908 25.0192 24.1841C25.3824 23.9775 25.6858 23.6801 25.8997 23.3211C26.1136 22.9621 26.2306 22.5538 26.2395 22.136C26.2483 21.7182 26.1485 21.3053 25.95 20.9376V20.9076ZM12.45 6.50006C12.45 6.22158 12.5606 5.95451 12.7575 5.7576C12.9544 5.56068 13.2215 5.45006 13.5 5.45006C13.7784 5.45006 14.0455 5.56068 14.2424 5.7576C14.4393 5.95451 14.55 6.22158 14.55 6.50006V15.5001C14.55 15.7785 14.4393 16.0456 14.2424 16.2425C14.0455 16.4394 13.7784 16.5501 13.5 16.5501C13.2215 16.5501 12.9544 16.4394 12.7575 16.2425C12.5606 16.0456 12.45 15.7785 12.45 15.5001V6.50006ZM13.5 21.3876C13.233 21.3876 12.9719 21.3084 12.7499 21.16C12.5279 21.0117 12.3549 20.8009 12.2527 20.5542C12.1505 20.3075 12.1238 20.0361 12.1759 19.7742C12.228 19.5123 12.3566 19.2718 12.5454 19.083C12.7342 18.8942 12.9747 18.7656 13.2366 18.7135C13.4985 18.6614 13.7699 18.6881 14.0166 18.7903C14.2633 18.8925 14.4741 19.0655 14.6224 19.2875C14.7708 19.5095 14.85 19.7706 14.85 20.0376C14.85 20.2148 14.815 20.3904 14.7472 20.5542C14.6794 20.718 14.5799 20.8668 14.4546 20.9922C14.3292 21.1175 14.1804 21.217 14.0166 21.2848C13.8528 21.3526 13.6772 21.3876 13.5 21.3876Z"
        fill="#ED6E5D"
      />
    </Svg>
  );
};

export default Attention;
