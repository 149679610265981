import moment from 'moment';

export const SHYFTER = {
  support: {
    email: 'support@shyfter.co',
    phone: '+32 2 211 34 54',
  },
  theme: {
    primary: '#00a651',
    secondary: '#f5fef8',
    background: '#29303d',
    disabled: '#3d4044',
    error: '#ed6e5d',
  },
};

export const ALPHABET_PAIRS = [
  ['A', 'B'],
  ['C', 'D'],
  ['E', 'F'],
  ['G', 'H'],
  ['I', 'J'],
  ['K', 'L'],
  ['M', 'N'],
  ['O', 'P'],
  ['Q', 'R'],
  ['S', 'T'],
  ['U', 'V'],
  ['W', 'X'],
  ['Y', 'Z'],
];

export const PIN_CODE_LENGTH = 4;
export const UTC_OFFSET = 2;
export const BLOCKED_ACCOUNT_TYPES = [
  'INACTIVE',
  'CHURN',
  'BLOCKED',
  'LEAD_EXPIRED',
];

export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_FULL = 'HH:mm:ss';
export const DATE_FORMAT = 'dddd, DD MMMM YYYY';
export const DATE_FORMAT_FULL = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY à HH:mm';
export const DATE_FORMAT_SHORT = 'DD/MM/YYYY';

type MESSAGE_ICON_NAME_TYPES = 'pause' | '';
type MESSAGE_FROM_TYPES = 'profile' | 'home' | 'start';
export type ParamList = {
  Loading: any;
  Start: any;
  Api: any;
  Pin: any;
  Home: any;
  Message: {
    message: string;
    tooltip: string;
    icon_name: MESSAGE_ICON_NAME_TYPES;
    from: MESSAGE_FROM_TYPES;
    stay?: boolean;
    time?: string;
  };
  Profile: any;
  Signature: any;
};

export interface IColors {
  background: string;
  contrast: string;
  brand: string;
  brand_secondary: string;
  disabled_light: string;
  disabled_dark: string;
  error: string;
}
