import { actions } from './deviceSlice';
import { store } from '@/redux/store';

type Dispatch = typeof store.dispatch;

// Set dimensions
export const setDimensions =
  ({ width, height }: SetDimensionsParams) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setDimensions({ width, height }));
    } catch (err) {
      console.log(err);
    }
  };
type SetDimensionsParams = {
  width: number;
  height: number;
};
