import { SHYFTER } from '@/globals';
import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import Text from '@ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';
import DefaultContainer from '../default/DefaultContainer';

interface Props {}

const BlockedScreen: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <DefaultContainer>
      <Text color={theme.error!} bold size={scale(20)}>
        {t('BLOCKED.ACCOUNT_BLOCKED')}
      </Text>
      <Text marginTop={scale(25)} bold color={getContrast(theme.background!)}>
        {t('GLOBAL.CALL_US')} {SHYFTER.support.phone}
      </Text>
    </DefaultContainer>
  );
};

export default BlockedScreen;
