import { TIME_FORMAT } from '@/globals';
import Text from '@ui/Text';
import 'moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { scale } from 'react-native-size-matters';

interface Props {
  size?: number;
}

const Time: React.FC<Props> = ({ size }) => {
  const [clock, setClock] = useState(moment().format(TIME_FORMAT));

  useEffect(() => {
    const interval = setInterval(() => {
      setClock(moment().format(TIME_FORMAT));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Text size={size ? size : scale(40)} semi marginBottom={scale(10)}>
      {clock}
    </Text>
  );
};

export default React.memo(Time);
