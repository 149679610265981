import { ALPHABET_PAIRS } from "@globals";
import IUser from "@models/IUser.model";
import { AppState } from "@redux/store";
import { FlashList } from "@shopify/flash-list";
import Text from "@ui/Text";
import React, { MutableRefObject } from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  marginRight: number;
  usersListRef: React.RefObject<FlashList<IUser>>;
}

const Alphabet: React.FC<Props> = ({ marginRight, usersListRef }) => {
  const { theme, users } = useSelector((state: AppState) => state.app);

  const onPress = (first_letter: string, second_letter: string) => {
    const firstUser = users?.find(
      (user) =>
        user.displayName.toUpperCase().startsWith(first_letter) ||
        user.displayName.toLocaleUpperCase().startsWith(second_letter)
    );

    usersListRef.current?.scrollToItem({ animated: true, item: firstUser! });
  };

  return (
    <View
      style={[styles.container, { marginRight: marginRight ? marginRight : 0 }]}
    >
      {ALPHABET_PAIRS.map(([first_letter, second_letter]) => (
        <TouchableOpacity
          key={`${first_letter}_${second_letter}`}
          onPress={() => onPress(first_letter, second_letter)}
          style={{
            borderColor: theme.contrast!,
            width: "100%",
            padding: scale(1),
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Text bold size={scale(7)}>
            {first_letter} - {second_letter}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
  },
});

export default React.memo(Alphabet);
