import { DATE_FORMAT_FULL, TIME_FORMAT_FULL, UTC_OFFSET } from "@/globals";
import { AppState } from "@/redux/store";
import { getContrast, humanize, lighten } from "@/utils";
import Text from "@ui/Text";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalClockingDetails: React.FC<Props> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_clocking } = useSelector((state: AppState) => state.clockings);

  const onBackdropPress = useCallback(() => {
    setVisible(false);
  }, []);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.secondary!,
          padding: scale(15),
          borderRadius: 5,
          width: width / 1.5,
          alignSelf: "center",
        },
        title: {
          marginBottom: 10,
        },
        item: {
          backgroundColor: lighten(theme.secondary!, 15),
          padding: 15,
          marginVertical: 5,
          borderRadius: 5,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        },
        break: {
          flexDirection: "row",
        },
        badge: {
          paddingHorizontal: 10,
          paddingVertical: 3,
        },
        clocking_badge: {
          backgroundColor: theme.primary!,
          borderRadius: 5,
        },
        break_badge: {
          backgroundColor: theme.error!,
          color: getContrast(theme.error!),
          borderRadius: 5,
        },
        pending_badge: {
          backgroundColor: theme.disabled_light!,
          color: getContrast(theme.disabled_light!),
          borderRadius: 5,
        },
      }),
    [theme]
  );

  const now = moment();
  const diff = useMemo(() => {
    if (active_clocking) {
      return moment(now.format(DATE_FORMAT_FULL)).diff(
        moment(
          moment
            .utc(active_clocking?.startdate! * 1000)
            .format(DATE_FORMAT_FULL)
        )
      );
    }
    return null;
  }, [active_clocking, now]);

  const duration = useMemo(() => {
    if (active_clocking) {
      return moment.duration(diff, "milliseconds");
    }
    return null;
  }, [active_clocking, diff]);

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <View style={styles.container}>
        <Text style={styles.title} color={getContrast(theme.secondary!)}>
          {t("PROFILE.CLOCKING_DETAILS")}
        </Text>
        <ScrollView>
          {active_clocking?.breaks &&
            [...active_clocking?.breaks!]
              .reverse()
              .map(({ id, startdate, enddate }) => {
                const start_moment = moment.utc(startdate * 1000);
                let start_diff = moment(now.format(DATE_FORMAT_FULL)).diff(
                  moment(moment.utc(startdate * 1000).format(DATE_FORMAT_FULL))
                );
                let end_diff = moment(
                  moment(moment.utc(enddate * 1000).format(DATE_FORMAT_FULL))
                ).diff(start_moment.format(DATE_FORMAT_FULL));

                let duration;
                if (enddate) {
                  duration = humanize(moment.duration(end_diff), t);
                } else {
                  duration = humanize(moment.duration(start_diff), t);
                }

                return (
                  <View key={id} style={[styles.item, styles.break]}>
                    {enddate ? (
                      <>
                        <Text
                          size={scale(7)}
                          color={getContrast(theme.secondary!)}
                        >
                          {t("GLOBAL.DATES_TIMES_RANGE", {
                            date_1: moment
                              .unix(startdate)
                              .utcOffset(UTC_OFFSET)
                              .format("DD-MM-YYYY"),
                            time_1: moment
                              .unix(startdate)
                              .utcOffset(UTC_OFFSET)
                              .format(TIME_FORMAT_FULL),
                            date_2: moment
                              .unix(enddate)
                              .utcOffset(UTC_OFFSET)
                              .format("DD-MM-YYYY"),
                            time_2: moment
                              .unix(enddate)
                              .utcOffset(UTC_OFFSET)
                              .format(TIME_FORMAT_FULL),
                          })}
                          <Text size={scale(5)} color={theme.disabled_light!}>
                            {" "}
                            ({duration})
                          </Text>
                        </Text>
                        <Text
                          style={[styles.badge, styles.break_badge]}
                          size={scale(7)}
                        >
                          {t("GLOBAL.BREAK")}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text
                          size={scale(7)}
                          color={getContrast(theme.secondary!)}
                        >
                          {t("PROFILE.IN_BREAK_SINCE", {
                            time: moment
                              .utc(startdate * 1000)
                              .format(TIME_FORMAT_FULL),
                          })}
                          <Text size={scale(5)} color={theme.disabled_light!}>
                            {" "}
                            ({duration})
                          </Text>
                        </Text>
                        <Text
                          style={[styles.badge, styles.pending_badge]}
                          size={scale(7)}
                        >
                          {t("GLOBAL.PENDING")}
                        </Text>
                      </>
                    )}
                  </View>
                );
              })}
          {active_clocking ? (
            <View style={styles.item}>
              <Text size={scale(7)} color={getContrast(theme.secondary!)}>
                {t("GLOBAL.DATE_TIME", {
                  date: moment
                    .utc(active_clocking.startdate * 1000)
                    .format("DD-MM-YYYY"),
                  time: moment
                    .utc(active_clocking.startdate * 1000)
                    .format(TIME_FORMAT_FULL),
                })}
                <Text size={scale(5)} color={theme.disabled_light!}>
                  {" "}
                  ({humanize(duration!, t)})
                </Text>
              </Text>
              <Text
                style={[styles.badge, styles.clocking_badge]}
                size={scale(7)}
                color={getContrast(theme.primary!)}
              >
                {t("GLOBAL.CLOCKING")}
              </Text>
            </View>
          ) : null}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default React.memo(ModalClockingDetails);
