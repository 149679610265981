import AsyncStorage from "@react-native-async-storage/async-storage";
import axios, { AxiosRequestConfig } from "axios";
import Constants from "expo-constants";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import * as Device from "expo-device";

const cancel_token = axios.CancelToken.source();

const instance = axios.create({
  baseURL: Constants.expoConfig?.extra?.API_URL,
  cancelToken: cancel_token.token,
});

instance.defaults.headers.common["os"] = Platform.OS;
instance.defaults.headers.common["app-version"] =
  Constants.expoConfig?.version || "undefined";
instance.defaults.headers.common["model-name"] =
  Device.modelName || "undefined";
instance.defaults.headers.common["os-build-id"] =
  Device.osBuildId || "undefined";
instance.defaults.headers.common["os-version"] =
  Device.osVersion || "undefined";

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (Platform.OS !== "web") {
      const api_key = await SecureStore.getItemAsync("api_key", {
        keychainAccessible: SecureStore.ALWAYS,
      });
      if (api_key) {
        const key = api_key?.split("#")[0];
        const section = api_key?.split("#")[1];
        const section_id = section ? section.split("=")[1] : null;
        config.params = {
          ...config.params,
          apiKey: key,
          sectionId: section_id,
        };
      }
    } else {
      const api_key = await AsyncStorage.getItem("api_key");
      if (api_key) {
        const key = api_key?.split("#")[0];
        const section = api_key?.split("#")[1];
        const section_id = section ? section.split("=")[1] : null;
        config.params = {
          ...config.params,
          apiKey: key,
          sectionId: section_id,
        };
      }
    }
    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default instance;
