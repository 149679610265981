import React, { Dispatch, SetStateAction } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Text from "@ui/Text";
import Digit from "@screens/pin/components/Digit";
import { scale } from "react-native-size-matters";
import { useTranslation } from "react-i18next";

interface Props {
  setCode: Dispatch<SetStateAction<string>>;
  code: string;
}

const FIRST_ROW_DIGITS = [1, 2, 3];
const SECOND_ROW_DIGITS = [4, 5, 6];
const THIRD_ROW_DIGITS = [7, 8, 9];
const FOURTH_ROW_DIGITS = [, 0];

const Dial: React.FC<Props> = ({ setCode, code }) => {
  return (
    <View>
      <View style={styles.row}>
        {FIRST_ROW_DIGITS.map((el) => (
          <Digit key={el} value={el} setCode={setCode} code={code} />
        ))}
      </View>
      <View style={styles.row}>
        {SECOND_ROW_DIGITS.map((el) => (
          <Digit key={el} value={el} setCode={setCode} code={code} />
        ))}
      </View>
      <View style={styles.row}>
        {THIRD_ROW_DIGITS.map((el) => (
          <Digit key={el} value={el} setCode={setCode} code={code} />
        ))}
      </View>
      <View style={styles.row}>
        {FOURTH_ROW_DIGITS.map((el) => (
          <Digit key={el} value={el} setCode={setCode} code={code} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "center",
  },
});

export default React.memo(Dial);
