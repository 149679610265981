import Close from '@/components/Close';
import { updateUser } from '@/redux/slices/app/functions';
import {
  clockingOut,
  reset,
  setAttribute,
  setBreak,
  setClockLoading,
  setSection,
  setShift,
  setSkill,
  setTask,
} from '@/redux/slices/clockings/functions';
import { AppDispatch, AppState } from '@/redux/store';
import ModalSelectSection from '@/screens/home/components/sections/ModalSelectSection';
import { IBreak } from '@/types/IBreak.model';
import IShift from '@/types/IShift.model';
import ITask from '@/types/ITask.model';
import Clock from '@components/Clock';
import ProfileImage from '@components/ProfileImage';
import { DATE_FORMAT_FULL, ParamList, TIME_FORMAT_FULL } from '@globals';
import CompanyLogo from '@icons/CompanyLogo';
import { useNavigation } from '@react-navigation/native';
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from '@react-navigation/native-stack';
import DefaultContainer from '@screens/default/DefaultContainer';
import Text from '@ui/Text';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import * as Network from 'expo-network';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanResponder, Platform, StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';
import ModalSelectAttribute from '../home/components/attributes/ModalSelectAttribute';
import ModalSelectSkill from '../home/components/skills/ModalSelectSkill';
import ModalSelectTask from '../home/components/tasks/ModalSelectTask';
import ChangeAttributeButton from './components/attributes/ChangeAttributeButton';
import SelectedAttributeBadge from './components/attributes/SelectedAttributeBadge';
import BreakInButton from './components/BreakInButton';
import BreakOutButton from './components/BreakOutButton';
import ModalClockingDetails from './components/clocking-details/ModalClockingDetails';
import ShowClockingDetailsButton from './components/clocking-details/ShowClockingDetailsButton';
import ClockOutButton from './components/ClockOutButton';
import ModalComment from './components/ModalComment';
import ChangeSectionButton from './components/sections/ChangeSectionButton';
import SelectedSectionBadge from './components/sections/SelectedSectionBadge';
import ChangeSkillButton from './components/skills/ChangeSkillButton';
import SelectedSkillBadge from './components/skills/SelectedSkillBadge';
import StartedDate from './components/StartedDate';
import ModalTasksHistory from './components/tasks-history/ModalTasksHistory';
import ShowTasksHistoryButton from './components/tasks-history/ShowTasksHistoryButton';
import ChangeTaskButton from './components/tasks/ChangeTaskButton';
import SelectedTaskBadge from './components/tasks/SelectedTaskBadge';
import WorkTime from './components/WorkTime';

interface Props extends NativeStackScreenProps<ParamList, 'Profile'> {}

const INACTIVITY_TIMER = 10000;
const ONE_HOUR_MS = 60 * 60 * 1000;

const ProfileScreen: React.FC<Props> = ({ route }) => {
  const { cameraRef } = route.params as any;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme, active_department, section_id } = useSelector(
    (state: AppState) => state.app
  );
  const {
    active_user,
    active_clocking,
    active_break,
    active_shift,
    active_section,
    active_task,
    active_skill,
  } = useSelector((state: AppState) => state.clockings);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalSelectSectionVisible, setModalSelectSectionVisible] =
    useState<boolean>(false);
  const [modalSelectSkillVisible, setModalSelectSkillVisible] =
    useState<boolean>(false);
  const [modalSelectTaskVisible, setModalSelectTaskVisible] =
    useState<boolean>(false);
  const [modalSelectAttributeVisible, setModalSelectAttributeVisible] =
    useState<boolean>(false);
  const [modalClockingDetailsVisible, setModalClockingDetailsVisible] =
    useState<boolean>(false);
  const [modalTasksHistoryVisible, setModalTasksHistoryVisible] =
    useState<boolean>(false);
  const [modalCommentVisible, setModalCommentVisible] =
    useState<boolean>(false);
  const [lastInteraction, setLastInteraction] = useState(Date.now());

  const { startdate: active_clocking_start } = active_clocking || {};
  const { today_shifts } = active_user || {};

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onMoveShouldSetPanResponder: (evt, gestureState) => {
          setLastInteraction(Date.now());
          return true;
        },
        onStartShouldSetPanResponderCapture: () => {
          setLastInteraction(Date.now());
          return false;
        },
        onMoveShouldSetPanResponderCapture: () => false,
        onPanResponderTerminationRequest: () => true,
        onShouldBlockNativeResponder: () => false,
      }),
    []
  );

  useEffect(() => {
    const inactivityCheck = setInterval(() => {
      if (Date.now() - lastInteraction > INACTIVITY_TIMER) {
        navigation.navigate('Start');
        dispatch(reset());
      }
    }, 1000);

    return () => clearInterval(inactivityCheck);
  }, [lastInteraction, INACTIVITY_TIMER]);

  useEffect(() => {
    if (modalCommentVisible) {
      setLastInteraction(Date.now() + ONE_HOUR_MS);
    } else {
      setLastInteraction(Date.now());
    }
  }, [
    active_user,
    modalSelectSectionVisible,
    modalSelectSkillVisible,
    modalSelectTaskVisible,
    modalSelectAttributeVisible,
    modalClockingDetailsVisible,
    modalCommentVisible,
  ]);

  useEffect(() => {
    return () => {
      setModalCommentVisible(false);
    };
  }, []);

  useEffect(() => {
    get();
  }, [active_user, active_clocking]);

  const get = useCallback(async () => {
    await setLoading(true);
    if (active_clocking) {
      if (today_shifts && today_shifts.length) {
        const found_shift = today_shifts.find(
          (shift: IShift) => shift.id === active_clocking!.shift_id
        );
        if (found_shift) {
          await dispatch(setShift(found_shift));

          const { section, skills, attribute } = found_shift;

          if (section) {
            await dispatch(setSection(section));
          }

          // if (skills && skills.length > 0) {
          //   await dispatch(setSkill(skills[0]));
          // }

          if (attribute) {
            await dispatch(setAttribute(attribute));
          }
        }

        const { tasks } = active_clocking;
        if (tasks) {
          const found_task = tasks.find((task: ITask) => {
            const now = moment(moment().format(DATE_FORMAT_FULL));
            const task_start_moment = moment(
              moment.utc(task.startdate * 1000).format(DATE_FORMAT_FULL)
            );

            return !task.enddate && now.isSameOrAfter(task_start_moment);
          });

          if (found_task) {
            await dispatch(setTask(found_task));
          }
        }

        await setLoading(false);
      } else {
        const { section, skill, tasks, attribute, breaks } = active_clocking;

        if (breaks.length > 0) {
          const last_break = breaks[breaks.length - 1];
          if (last_break && !last_break.enddate) {
            await dispatch(setBreak(last_break));
          }
        }

        await dispatch(setSection(section));

        await dispatch(setSkill(skill));

        if (tasks) {
          const found_task = tasks.find((task: ITask) => {
            const task_start_moment = moment(task.startdate);
            return moment().isAfter(task_start_moment) && !task.enddate;
          });
          if (found_task) {
            await dispatch(setTask(found_task));
          } else {
            // await dispatch(setTask(null));
          }
        }

        await dispatch(setAttribute(attribute));
      }
    }
  }, [active_clocking, today_shifts]);

  useEffect(() => {
    dispatch(updateUser({ ...active_user!, clocking: active_clocking }));
  }, [active_clocking]);

  const checkout = async (comment: string | null = null) => {
    try {
      await dispatch(setClockLoading(true));
      setLastInteraction(Date.now());
      let picture = null;
      if (active_department?.params.tablet_picture) {
        const image = await cameraRef.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      const network = await Network.getNetworkStateAsync();
      const { granted } = await Camera.getCameraPermissionsAsync();

      await dispatch(
        clockingOut({
          clocking_id: active_clocking!.id,
          end: moment().unix().toString(),
          picture: picture ? picture.base64 : undefined,
          comment,
          infos: {
            version: Constants.expoConfig?.version,
            os: Platform.OS,
            model_name: Device.modelName,
            has_internet: network.isConnected,
            internet_reachable: network.isInternetReachable,
            camera_granted: granted,
            notifications: false,
            total_disk_space:
              Platform.OS !== 'web'
                ? `${await FileSystem.getTotalDiskCapacityAsync()}`
                : null,
            free_disk_space:
              Platform.OS !== 'web'
                ? `${await FileSystem.getFreeDiskStorageAsync()}`
                : null,
            ram: Device.totalMemory ? `${Device.totalMemory}` : null,
          },
        })
      );

      await setModalCommentVisible(false);

      await navigation.navigate('Message', {
        tooltip: t('MESSAGE.TOOLTIPS.SEE_YOU_NEXT_TIME'),
        message: t('MESSAGE.MESSAGES.YOU_JUST_CHECKED_OUT'),
        icon_name: 'pause',
        from: 'profile',
        time: compute(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onCheckout = useCallback(async () => {
    if (active_department?.params.comment) {
      setModalCommentVisible(true);
    } else {
      checkout();
    }
  }, [active_department, active_clocking]);

  const compute = useCallback(() => {
    if (active_clocking) {
      const now = moment();

      const clocking_diff = moment(now.format(DATE_FORMAT_FULL)).diff(
        moment(
          moment.utc(active_clocking_start! * 1000).format(DATE_FORMAT_FULL)
        )
      );

      const clocking_duration = moment.utc(
        moment.duration(clocking_diff, 'milliseconds').asMilliseconds()
      );

      const breaks_duration = active_clocking?.breaks.reduce(
        (total: number, currentValue: IBreak) => {
          const { startdate, enddate } = currentValue;
          const start = moment(startdate);
          if (enddate) {
            const end = moment(enddate);
            const break_duration = moment
              .duration(end.diff(start))
              .asMilliseconds();
            return total + break_duration;
          } else {
            const break_duration = moment
              .duration(now.diff(start))
              .asMilliseconds();
            return total + break_duration;
          }
        },
        0
      );

      if (active_department?.params.paid_clocking_break) {
        return clocking_duration.format(TIME_FORMAT_FULL);
      } else {
        return clocking_duration
          .subtract(breaks_duration, 'seconds')
          .format(TIME_FORMAT_FULL);
      }
    }
  }, [active_clocking, active_clocking_start, active_department]);

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        alignItems: 'center',
      },
      profile_picture_container: {
        marginBottom: scale(10),
      },
      actions_container: {
        margin: scale(25),
        width: width / 1.2,
        gap: scale(2),
      },
      subactions: {
        justifyContent: 'space-between',
      },
      badges: {
        flexDirection: 'row',
        marginTop: scale(5),
      },
      details_container: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: scale(10),
        left: scale(10),
      },
    });
  }, [width]);

  return (
    <View
      {...(Platform.OS === 'ios' ? { ...panResponder.panHandlers } : undefined)}
      style={{ flex: 1 }}
    >
      <ModalSelectSection
        from='profile'
        visible={modalSelectSectionVisible}
        setVisible={setModalSelectSectionVisible}
        camera={cameraRef}
      />
      <ModalSelectSkill
        active_shift={active_shift}
        from='profile'
        visible={modalSelectSkillVisible}
        setVisible={setModalSelectSkillVisible}
        camera={cameraRef}
      />
      <ModalSelectTask
        from='profile'
        visible={modalSelectTaskVisible}
        setVisible={setModalSelectTaskVisible}
        camera={cameraRef}
      />
      <ModalSelectAttribute
        from='profile'
        visible={modalSelectAttributeVisible}
        setVisible={setModalSelectAttributeVisible}
        camera={cameraRef}
      />
      <ModalClockingDetails
        visible={modalClockingDetailsVisible}
        setVisible={setModalClockingDetailsVisible}
      />
      <ModalTasksHistory
        visible={modalTasksHistoryVisible}
        setVisible={setModalTasksHistoryVisible}
      />
      {active_department?.params.comment ? (
        <ModalComment
          visible={modalCommentVisible}
          setVisible={setModalCommentVisible}
          checkout={checkout}
          setLastInteraction={setLastInteraction}
        />
      ) : null}
      <DefaultContainer>
        <Clock withTime />
        <Close />
        <View style={styles.container}>
          <View style={styles.profile_picture_container}>
            <ProfileImage />
          </View>
          {active_department?.params.worked_hours && <WorkTime />}
          <Text bold uppercase size={scale(10)}>
            {active_user?.displayName}
          </Text>
          <StartedDate />
          <View style={styles.badges}>
            {!section_id ? <SelectedSectionBadge camera={cameraRef} /> : null}
            <SelectedSkillBadge camera={cameraRef} />
            <SelectedAttributeBadge camera={cameraRef} />
          </View>
          <SelectedTaskBadge camera={cameraRef} />
          <View style={styles.actions_container}>
            {!active_break ? (
              <>
                <View style={{ flexDirection: 'row' }}>
                  <ClockOutButton onCheckout={onCheckout} />
                  <BreakInButton camera={cameraRef} />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  {!section_id ? (
                    <ChangeSectionButton
                      setModalSelectSectionVisible={
                        setModalSelectSectionVisible
                      }
                    />
                  ) : null}
                  <ChangeSkillButton
                    setModalSelectSkillVisible={setModalSelectSkillVisible}
                  />
                  <ChangeTaskButton
                    setModalSelectTaskVisible={setModalSelectTaskVisible}
                  />
                  <ChangeAttributeButton
                    setModalSelectAttributeVisible={
                      setModalSelectAttributeVisible
                    }
                  />
                </View>
              </>
            ) : null}
            {active_break ? (
              <View style={{ flexDirection: 'row' }}>
                <BreakOutButton camera={cameraRef} />
                <ClockOutButton onCheckout={onCheckout} flex={0.25} secondary />
              </View>
            ) : null}
          </View>
        </View>
        <View style={styles.details_container}>
          <ShowClockingDetailsButton
            setModalClockingDetailsVisible={setModalClockingDetailsVisible}
          />
          <ShowTasksHistoryButton
            setModalTasksHistoryVisible={setModalTasksHistoryVisible}
          />
        </View>
        <CompanyLogo bottom custom={theme.logo} />
      </DefaultContainer>
    </View>
  );
};

export default ProfileScreen;
