import Close from '@/components/Close';
import ProfileImage from '@/components/ProfileImage';
import { updateUser } from '@/redux/slices/app/functions';
import { signDocument } from '@/redux/slices/clockings/functions';
import { ILayout } from '@/types/ILayout.model';
import IPoint from '@/types/IPoint.model';
import Clock from '@components/Clock';
import { ParamList } from '@globals';
import CompanyLogo from '@icons/CompanyLogo';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import ContractModal from '@screens/signature/components/ContractModal';
import customHtml from '@screens/signature/components/customHtml';
import Button from '@ui/Button';
import Text from '@ui/Text';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Signature, { SignatureViewRef } from 'react-native-signature-canvas';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../redux/store';
import DefaultContainer from '../default/DefaultContainer';
import GesturePath from './components/GesturePath';
import GestureRecorder from './components/GestureRecorder';

const SignatureScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NativeStackNavigationProp<ParamList>>();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_user } = useSelector((state: AppState) => state.clockings);
  const [visible, setVisible] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paths, setPaths] = useState<IPoint[][]>([]);
  const gestureRecorder = useRef(null);
  const svgRef = useRef(null);
  const [layout, setLayout] = useState<ILayout>({
    width: 0,
    height: 0,
  });

  const signature_ref = useRef<SignatureViewRef>(null);

  const onSeeContract = () => {
    setVisible(!visible);
  };

  const onOk = (value: any) => {
    if (value) {
      setSignature(value);
    }
  };

  const onNext = async () => {
    if (paths.length || signature) {
      const { contract_to_sign } = active_user!;
      let signature_image = signature;

      if (Platform.OS === 'web') {
        const svgElement = document.getElementById('svg_element');
        if (svgElement) {
          var svgString = new XMLSerializer().serializeToString(svgElement);
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          canvas.width = layout.width;
          canvas.height = layout.height;
          var DOMURL = self.URL || self.webkitURL || self;
          var img = new Image();
          img.width = layout.width;
          img.height = layout.height;
          var svg = new Blob([svgString], {
            type: 'image/svg+xml;charset=utf-8',
          });
          var url = DOMURL.createObjectURL(svg);
          img.onload = async function () {
            if (ctx) {
              ctx.drawImage(img, 0, 0);
              var png = canvas.toDataURL('image/png');
              await setLoading(true);
              await dispatch(
                signDocument({
                  documentType: 'CONTRACT',
                  documentId: contract_to_sign!.id,
                  signature: png!,
                })
              );
              await dispatch(
                updateUser({ ...active_user!, contract_to_sign: null })
              );
              navigation.navigate('Home');
              await setLoading(false);
              DOMURL.revokeObjectURL(png);
            }
          };
          img.src = url;
        }
      } else {
        await setLoading(true);
        await dispatch(
          signDocument({
            documentType: 'CONTRACT',
            documentId: contract_to_sign!.id,
            signature: signature_image!,
          })
        );
        await dispatch(updateUser({ ...active_user!, contract_to_sign: null }));
        navigation.navigate('Home');

        await setLoading(false);
      }
    }
  };

  const onClear = () => {
    if (Platform.OS === 'web') {
      if (gestureRecorder.current) {
        (gestureRecorder.current as any).clearSignature();
      }
      setPaths([]);
    } else {
      setSignature(undefined);
      signature_ref.current?.clearSignature();
    }
  };

  const onEnd = () => {
    signature_ref.current?.readSignature();
  };

  const onPathChanged = (paths: IPoint[][]) => {
    setPaths(paths);
  };

  const styles = StyleSheet.create({
    main_container: {
      alignItems: 'center',
      width: width / 2,
    },
    signature_area: {
      width: '100%',
      height: scale(100),
      marginVertical: scale(20),
      borderRadius: 10,
      borderWidth: 3,
      borderColor: '#FAF2F0',
      borderStyle: 'dashed',
    },
    actions: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return (
    <DefaultContainer>
      <ContractModal visible={visible} setVisible={setVisible} />
      <Clock withTime />
      <Close />
      {!loading ? (
        <View style={styles.main_container}>
          <ProfileImage ratio={0.8} />
          <Text white uppercase bold size={scale(10)}>
            {active_user?.displayName}
          </Text>
          <Text white size={scale(10)} center marginTop={5}>
            {t('SIGNATURE.CERTIFY_ON_HONOR')}
          </Text>
          <TouchableOpacity onPress={onSeeContract}>
            <Text
              semi
              uppercase
              underline
              white
              size={scale(10)}
              marginTop={15}
            >
              {t('SIGNATURE.SEE_MY_CONTRACT')}
            </Text>
          </TouchableOpacity>
          <View
            style={styles.signature_area}
            onLayout={(e) => {
              setLayout({
                height: e.nativeEvent.layout.height,
                width: e.nativeEvent.layout.width,
              });
            }}
          >
            {Platform.OS === 'web' ? (
              <View style={{ backgroundColor: '#FFF' }}>
                <GesturePath
                  paths={paths}
                  color='#4e85d9'
                  ref={svgRef}
                  layout={layout}
                  width={5}
                />
                <GestureRecorder
                  onPathChanged={onPathChanged}
                  ref={gestureRecorder}
                />
              </View>
            ) : (
              <Signature
                ref={signature_ref}
                penColor='#4e85d9'
                minWidth={3}
                customHtml={customHtml}
                onOK={onOk}
                onEnd={onEnd}
              />
            )}
          </View>
          <View style={styles.actions}>
            <Button
              value={t('GLOBAL.SIGN')}
              primary
              onPress={onNext}
              marginHorizontal={scale(5)}
              disabled={
                Platform.OS === 'web'
                  ? !paths.length
                  : signature == undefined || signature == null
              }
            />
            <Button
              value={t('GLOBAL.CLEAR')}
              onPress={onClear}
              marginHorizontal={scale(5)}
            />
          </View>
        </View>
      ) : (
        <ActivityIndicator color={theme.primary!} />
      )}
      <CompanyLogo bottom custom={theme.logo} />
    </DefaultContainer>
  );
};

export default SignatureScreen;
