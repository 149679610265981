import CameraWrapper from '@/components/CameraWrapper';
import i18n from '@/i18n.config';
import {
  getPosData,
  getTheme,
  registerTablet,
  setDepartment,
  setDepartmentParams,
  setLoading,
  setTheme,
} from '@/redux/slices/app/functions';
import { setDimensions } from '@/redux/slices/device/functions';
import BlockedScreen from '@/screens/blocked';
import HomeScreen from '@/screens/home';
import MessageScreen from '@/screens/message';
import NoCameraScreen from '@/screens/permissions/NoCamera';
import ProfileScreen from '@/screens/profile';
import SignatureScreen from '@/screens/signature';
import { BLOCKED_ACCOUNT_TYPES, ParamList } from '@globals';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AppDispatch, AppState } from '@redux/store';
import ApiScreen from '@screens/api';
import LoadingScreen from '@screens/loading';
import PinScreen from '@screens/pin';
import StartScreen from '@screens/start';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as SecureStore from 'expo-secure-store';
import React, { useEffect } from 'react';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from 'sentry-expo';

interface Props {}

const Stack = createNativeStackNavigator<ParamList>();

let Pusher: any = null;

if (Platform.OS !== 'web') {
  Pusher = require('pusher-js/react-native');
} else {
  Pusher = require('pusher-js');
}

const cameraRef = React.createRef<Camera>();

const MainNavigator: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { api_key, active_account, active_department, loading } = useSelector(
    (state: AppState) => state.app
  );

  const [permission, requestCamera] = Camera.useCameraPermissions();

  const blocked =
    active_account &&
    BLOCKED_ACCOUNT_TYPES.includes(active_account.account_type);

  const picture_mandatory = active_department?.params.tablet_picture;

  const requestWebCamera = async () => {
    const available = await Camera.isAvailableAsync();
    if (available) {
      await Camera.requestCameraPermissionsAsync();
    }
  };

  useEffect(() => {
    if (!api_key) return;

    const pusher = new Pusher(Constants.expoConfig?.extra?.PUSHER_KEY, {
      cluster: Constants.expoConfig?.extra?.PUSHER_CLUSTER,
      forceTLS: true,
      authEndpoint: `${Constants.expoConfig?.extra?.API_URL}/broadcasting/auth`,
      auth: {
        params: {
          apiKey: api_key,
        },
      },
    });

    const channel = pusher.subscribe(`private-pos.${api_key}`);

    if (!channel) return;

    channel.bind('pos-changes', async (message: any) => {
      dispatch(setTheme({ ...message }));
      dispatch(setDepartmentParams({ ...message }));
    });

    channel.bind('pos-day-comment', async (message: any) => {
      dispatch(setDepartment(message.dayComment));
    });

    return () => {
      channel.unsubscribe(`private-pos.${api_key}`);
    };
  }, [api_key]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (permission?.status !== 'granted') {
        requestCamera();
      }
    } else {
      if (
        permission &&
        permission.canAskAgain &&
        permission.status == 'undetermined'
      ) {
        requestWebCamera();
      }
    }
  }, []);

  useEffect(() => {
    if (active_department) {
      i18n.changeLanguage(active_department.language);
    }
  }, [active_department]);

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window, screen }) => {
        const { height, width } = window;
        dispatch(setDimensions({ width, height: height }));
      }
    );
    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      SecureStore.getItemAsync('api_key', {
        keychainAccessible: SecureStore.ALWAYS,
      }).then(async (apiKey) => {
        if (apiKey) {
          dispatch(registerTablet(apiKey));
          dispatch(getPosData());
        } else {
          dispatch(getTheme());
          dispatch(setLoading(false));
        }
      });
    } else {
      AsyncStorage.getItem('api_key').then(async (apiKey) => {
        if (apiKey) {
          dispatch(registerTablet(apiKey));
          dispatch(getPosData());
        } else {
          dispatch(getTheme());
          dispatch(setLoading(false));
        }
      });
    }
  }, []);

  if (api_key) {
    if (Platform.OS !== 'web') {
      Sentry.Native.setTag('environment', process.env.NODE_ENV);
      Sentry.Native.setTag('os', Platform.OS);
      Sentry.Native.setTag('app-version', Constants.expoConfig?.version);
      Sentry.Native.setTag('api_key', api_key);
      Sentry.Native.setTag('model-name', Device.modelName);
      Sentry.Native.setTag('build-id', Device.osBuildId);
      Sentry.Native.setTag('os-version', Device.osVersion);
      Sentry.Native.setTag('camera-granted', permission?.status == 'granted');
      Sentry.Native.setTag(
        'camera-required',
        active_department?.params.tablet_picture
      );
    } else {
      Sentry.Browser.setTag('environment', process.env.NODE_ENV);
      Sentry.Browser.setTag('os', Platform.OS);
      Sentry.Browser.setTag('app-version', Constants.expoConfig?.version);
      Sentry.Browser.setTag('api_key', api_key);
      Sentry.Browser.setTag('model-name', Device.modelName);
      Sentry.Browser.setTag('build-id', Device.osBuildId);
      Sentry.Browser.setTag('os-version', Device.osVersion);
      Sentry.Browser.setTag('camera-granted', permission?.status == 'granted');
      Sentry.Browser.setTag(
        'camera-required',
        active_department?.params.tablet_picture
      );
    }
  }

  if (loading) {
    return (
      <View style={styles.spinner_container}>
        <LoadingScreen />
      </View>
    );
  }

  if (blocked) {
    return (
      <View style={styles.blocked_container}>
        <BlockedScreen />
      </View>
    );
  }

  if (permission && permission.status !== 'granted' && picture_mandatory) {
    return (
      <View style={styles.blocked_container}>
        <NoCameraScreen />
      </View>
    );
  }

  return (
    <>
      <StackNavigator />
      <FlashMessage position='top' animated />
    </>
  );
};

const StackNavigator = () => {
  const { api_key, active_department } = useSelector(
    (state: AppState) => state.app
  );

  const picture_mandatory = active_department?.params.tablet_picture;

  return (
    <>
      {picture_mandatory && <CameraWrapper ref={cameraRef} />}
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
          animation: 'none',
        }}
        initialRouteName={api_key ? 'Start' : 'Api'}
      >
        <Stack.Screen
          name='Start'
          component={StartScreen}
          options={{ title: 'POS - Home' }}
        />
        <Stack.Screen
          name='Api'
          component={ApiScreen}
          options={{ title: 'POS - API' }}
        />
        <Stack.Screen
          name='Pin'
          component={PinScreen}
          options={{ title: 'POS - Pin' }}
        />
        <Stack.Screen
          name='Home'
          component={HomeScreen}
          options={{ title: 'POS - User' }}
          initialParams={{ cameraRef }}
        />
        <Stack.Screen
          name='Message'
          component={MessageScreen}
          options={{ title: 'POS - Message' }}
        />
        <Stack.Screen
          name='Profile'
          component={ProfileScreen}
          options={{ title: 'POS - Profile' }}
          initialParams={{ cameraRef }}
        />
        <Stack.Screen
          name='Signature'
          component={SignatureScreen}
          options={{ title: 'POS - Signature' }}
        />
      </Stack.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  spinner_container: {
    flex: 1,
    justifyContent: 'center',
  },
  blocked_container: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default MainNavigator;
