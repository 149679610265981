import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import React from "react";
import { scale } from "react-native-size-matters";
import Svg, { Path } from "react-native-svg";
import { useSelector } from "react-redux";

interface Props {
  color?: string | null;
}

const CloseCross: React.FC<Props> = ({ color }) => {
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <Svg width={scale(10)} height={scale(10)} viewBox="0 0 24 24">
      <Path
        fill="none"
        stroke={color ? color : getContrast(theme.secondary!)}
        strokeWidth="2"
        d="M7,7 L17,17 M7,17 L17,7"
      />
    </Svg>
  );
};

export default React.memo(CloseCross);
