import { View, Text } from "react-native";
import React, { useMemo } from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import { scale } from "react-native-size-matters";

interface Props {
  setModalSelectTaskVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeTaskButton: React.FC<Props> = ({ setModalSelectTaskVisible }) => {
  const { t } = useTranslation();
  const { tasks, theme } = useSelector((state: AppState) => state.app);
  const { change_task_loading } = useSelector(
    (state: AppState) => state.clockings
  );

  const onTaskPress = () => {
    //   setLastInteraction(Date.now());
    setModalSelectTaskVisible(true);
  };

  const show_tasks = useMemo(() => {
    return tasks && tasks.length > 0;
  }, [tasks]);

  if (!show_tasks) return null;

  return (
    <Button
      value={t("GLOBAL.TASK")}
      color={getContrast(theme.background!)}
      textColor={getContrast(getContrast(theme.background!))}
      onPress={onTaskPress}
      style={{
        flex: 1,
        marginRight: scale(2),
        marginLeft: scale(2),
        marginTop: scale(5),
      }}
      loading={change_task_loading}
    />
  );
};

export default React.memo(ChangeTaskButton);
