import React from "react";
import { Image, View } from "react-native";
import { scale } from "react-native-size-matters";
import Svg, { Path } from "react-native-svg";

interface Props {
  custom: string | null;
  center?: boolean;
  marginRight?: number;
  marginBottom?: number | string;
  bottom?: boolean;
}

const CompanyLogo: React.FC<Props> = ({
  custom,
  center,
  marginRight,
  marginBottom,
  bottom,
}) => {
  return (
    <View
      style={{
        position: bottom ? "absolute" : "relative",
        marginBottom: marginBottom ? marginBottom : 0,
        bottom: bottom ? scale(10) : 0,
        right: bottom ? scale(10) : 0,
        alignSelf: center ? "center" : "flex-end",
      }}
    >
      {custom ? (
        <Image
          style={{ width: scale(25), height: scale(25) }}
          source={{
            uri: custom,
          }}
        />
      ) : (
        <Svg
          width={scale(75)}
          height={scale(25)}
          viewBox="0 0 177 39"
          fill="none"
        >
          <Path
            d="M53.2259 17.4124C50.0443 16.1686 48.8811 16.1341 48.8811 15.0631C48.8811 14.4412 49.189 14.0957 50.3864 14.0957C51.9259 14.0612 53.8417 14.4067 56.8864 15.4086L58.3917 10.3645C55.0732 9.18985 52.6443 8.74072 49.6337 8.74072C45.0837 8.74072 42.3811 10.9173 42.3811 15.6504C42.3811 19.4162 44.1258 21.0054 47.7864 22.3528C51.0364 23.5275 52.0285 23.7002 52.0285 24.633C52.0285 25.324 51.6864 25.7386 50.6258 25.7386C49.0864 25.7386 46.5206 24.9094 43.6469 23.2511L41.6627 28.3643C44.5706 30.1953 47.9574 31.2663 50.9337 31.2663C55.5522 31.2663 58.5627 28.6752 58.5627 23.9421C58.5285 19.7617 56.1338 18.5525 53.2259 17.4124Z"
            fill="white"
          />
          <Path
            d="M75.3602 8.74089C72.8628 8.74089 70.7418 9.77734 67.8681 12.2994V8.15357V2.00393V0.034668H59.6917V2.00393V8.15357V31.3356H67.8681V18.7945C68.9628 17.5508 70.126 16.7561 71.5286 16.7561C73.2049 16.7561 73.547 17.4817 73.547 19.7964V31.3356H81.7234V17.2053C81.7234 11.1593 79.9444 8.74089 75.3602 8.74089Z"
            fill="white"
          />
          <Path
            d="M134.818 25.1168C132.8 25.1168 132.287 24.1494 132.287 22.5256V15.9614H137.418V9.15535H132.287V4.42221H124.11V9.1208H122.024H120.142H116.755V8.74077C116.755 7.18609 117.302 6.14963 119.389 6.14963L122.16 6.21873L122.708 0.552775C121.066 0.172742 119.629 0 117.816 0C111.487 0 108.613 2.59114 108.613 8.67167V9.1208H105.739L97.8708 9.15535C96.5024 14.2685 95.3734 17.9998 93.7313 21.5928L92.7392 18.1725L89.455 9.15535H81.0049L88.7708 29.2971C87.1286 30.8864 85.4181 31.8192 83.2971 32.7174L85.9655 38.9362C92.6366 36.7596 96.8103 32.8556 99.8208 27.086C101.394 24.0458 102.866 20.1763 104.337 15.9614H105.739H108.613V31.3355H116.789V15.9614H121.134H122.024H124.11V22.9057C124.11 29.228 126.368 31.7155 131.637 31.7155C134.066 31.7155 136.871 31.0246 138.41 30.0572L137.589 25.0822C136.563 25.1168 135.776 25.1168 134.818 25.1168Z"
            fill="white"
          />
          <Path
            d="M159.347 19.3818C159.347 12.5066 155.345 8.7063 148.947 8.7063C141.866 8.7063 137.658 12.8176 137.658 20.3837C137.658 27.8116 142.003 31.7501 148.947 31.7501C152.71 31.7501 155.824 30.7137 159.347 28.1917L157.055 23.4931C154.25 24.8059 152.3 25.3932 149.324 25.3932C147.1 25.3932 145.971 24.5295 145.56 22.4221H159.142C159.279 21.1438 159.347 20.3837 159.347 19.3818ZM145.526 18.0689C145.903 15.5469 147.1 14.3723 148.947 14.3723C151.034 14.3723 152.163 15.6505 152.232 18.0689H145.526Z"
            fill="white"
          />
          <Path
            d="M174.297 8.74072C171.868 8.74072 170.192 10.1572 168.55 13.4048V9.12076H160.374V31.3354H168.55V21.04C169.405 17.8961 171.218 17.2051 173.887 17.2051C174.639 17.2051 175.392 17.2051 176.453 17.3088L177 8.91347C175.768 8.74072 175.05 8.74072 174.297 8.74072Z"
            fill="white"
          />
          <Path
            d="M36.0521 9.22521L14.431 0.346246C12.3442 -0.517466 9.94941 0.518988 9.09414 2.62645L0.302004 24.4266C-0.553262 26.534 0.473058 28.987 2.55991 29.8161L24.1468 38.6951C26.2337 39.5588 28.6626 38.5223 29.4837 36.4149L38.2758 14.6148C39.1311 12.5419 38.139 10.0889 36.0521 9.22521ZM6.97308 13.9929H22.6758V18.9679H6.97308V13.9929ZM31.6047 25.083H11.7968V20.108H31.6047V25.083Z"
            fill="#00A651"
          />
        </Svg>
      )}
    </View>
  );
};

export default React.memo(CompanyLogo);
