import { SHYFTER } from '@/globals';
import { AppState } from '@/redux/store';
import { getContrast } from '@/utils';
import Text from '@ui/Text';
import React, { ReactNode } from 'react';
import { ActivityIndicator, StyleSheet, TouchableOpacity } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useSelector } from 'react-redux';

interface Props {
  value?: string;
  element?: ReactNode;
  fontFamily?: string;
  onPress: () => void;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  marginBottom?: number;
  marginTop?: number;
  marginHorizontal?: number;
  width?: number | string;
  height?: number;
  icon?: any;
  justifyContent?: 'center' | 'space-between';
  small?: boolean;
  textColor?: string;
  loading?: boolean;
  style?: any;
  no_style?: boolean;
  error?: boolean;
  color?: string;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  value,
  element,
  fontFamily,
  onPress,
  primary,
  marginBottom,
  marginTop,
  marginHorizontal,
  width,
  height,
  icon,
  secondary,
  tertiary,
  justifyContent,
  small,
  textColor,
  loading,
  style,
  no_style,
  error,
  color,
  disabled,
}) => {
  const { theme } = useSelector((state: AppState) => state.app);

  const styles = StyleSheet.create({
    container: {
      borderWidth: !no_style ? scale(1) : 0,
      paddingHorizontal: !no_style ? scale(small ? 5 : 10) : 0,
      paddingVertical: !no_style ? scale(small ? 2 : 4) : 0,
      borderRadius: !no_style ? 5 : 0,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });

  const background = disabled
    ? theme.disabled!
    : loading
    ? theme.disabled!
    : color
    ? color
    : primary
    ? theme.primary!
    : secondary
    ? theme.secondary!
    : tertiary
    ? theme.secondary!
    : 'transparent';

  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          borderColor: disabled
            ? theme.disabled!
            : loading
            ? theme.disabled!
            : color
            ? color
            : primary
            ? theme.primary!
            : secondary
            ? theme.secondary!
            : tertiary
            ? theme.secondary!
            : theme.contrast!,
          backgroundColor: error ? SHYFTER.theme.error : background,
          marginBottom: marginBottom ? marginBottom : 0,
          marginTop: marginTop ? marginTop : 0,
          marginHorizontal: marginHorizontal ? marginHorizontal : 0,
          width: width ? width : 'auto',
          height: height ? height : 'auto',
          justifyContent: justifyContent ? justifyContent : 'center',
          ...style,
        },
      ]}
      onPress={loading ? () => {} : onPress}
    >
      {loading && <ActivityIndicator color={theme.primary!} />}
      {!element ? (
        <Text
          size={scale(small ? 7 : 10)}
          fontFamily={fontFamily ? fontFamily : 'Poppins_700Bold'}
          center
          secondary={secondary || tertiary}
          loading={loading}
          marginLeft={loading ? scale(10) : 0}
          color={textColor ? textColor : getContrast(background)}
        >
          {value}
        </Text>
      ) : null}
      {element}
      {icon}
    </TouchableOpacity>
  );
};

Button.defaultProps = {
  justifyContent: 'center',
};

export default Button;
