import React from "react";
import Text from "@components/ui/Text";
import { scale } from "react-native-size-matters";
import { useTranslation } from "react-i18next";
import { AppState } from "@/redux/store";
import { useSelector } from "react-redux";

const HelloText = () => {
  const { t } = useTranslation();
  const { active_user } = useSelector((state: AppState) => state.clockings);

  return (
    <Text size={scale(15)} uppercase bold>
      {t("HOME.HELLO")} {active_user?.displayName} !
    </Text>
  );
};

export default React.memo(HelloText);
