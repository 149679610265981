import Attention from '@/components/icons/Attention';
import Begin from '@/components/icons/Begin';
import { DATE_FORMAT_FULL } from '@/globals';
import IShift from '@/types/IShift.model';
import { humanize } from '@/utils';
import Text from '@ui/Text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';

interface Props {
  active_shift: IShift;
}

const Countdown: React.FC<Props> = ({ active_shift }) => {
  const { t } = useTranslation();
  const [now, setNow] = useState<moment.Moment>(
    moment(moment().format(DATE_FORMAT_FULL))
  );

  const active_shift_start_moment = moment(
    moment.utc(active_shift?.startdate * 1000).format(DATE_FORMAT_FULL)
  );
  const is_late = now.isAfter(active_shift_start_moment);
  const duration = moment.duration(now.diff(active_shift_start_moment)).abs();

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment(moment().format(DATE_FORMAT_FULL)));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {is_late ? (
        <View style={styles.late_text}>
          <Attention />
          <Text error size={scale(10)} marginLeft={scale(3)}>
            {t('HOME.TIME_LATE', {
              time: humanize(duration, t),
            })}
          </Text>
        </View>
      ) : (
        <View style={styles.late_text}>
          <Begin />
          <Text size={scale(10)} marginLeft={scale(5)}>
            {t('HOME.SHIFT_STARTS_IN', {
              time: humanize(duration, t),
            })}
          </Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  late_text: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default Countdown;
