import React from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";

interface Props {
  setModalTasksHistoryVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowTasksHistoryButton: React.FC<Props> = ({
  setModalTasksHistoryVisible,
}) => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_clocking } = useSelector((state: AppState) => state.clockings);

  const onTasksHistory = () => {
    //   setLastInteraction(Date.now());
    setModalTasksHistoryVisible(true);
  };

  const show_button =
    active_clocking?.tasks && active_clocking?.tasks.length > 0;

  if (!show_button) return null;

  return (
    <Button
      value={t("PROFILE.TASKS_HISTORY")}
      small
      onPress={onTasksHistory}
      textColor={getContrast(theme.background!)}
    />
  );
};

export default React.memo(ShowTasksHistoryButton);
