import IPoint from '@/types/IPoint.model';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { PanResponder, StyleSheet, View } from 'react-native';

const GestureRecorder = forwardRef((props: any, ref: any) => {
  const pathRef = useRef<IPoint[][]>([[]]);
  const { onPathChanged } = props;

  useImperativeHandle(ref, () => ({
    clearSignature() {
      pathRef.current = [[]];
    },
  }));

  const panResponder = useRef(
    PanResponder.create({
      // Next line is needed to work on android
      onMoveShouldSetPanResponderCapture(e, gestureState) {
        return true;
      },
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pathRef.current.push([]);
      },
      onPanResponderMove: (event: any) => {
        const length = pathRef.current.length;
        pathRef.current[length - 1].push({
          x: event.nativeEvent.locationX,
          y: event.nativeEvent.locationY,
        });
        // Uncomment the next line to draw the path as the user is performing the touch. (A new array must be created so setState recognises the change and re-renders the App)
        onPathChanged([...pathRef.current]);
      },
      onPanResponderRelease: () => {
        onPathChanged([...pathRef.current]);
      },
    })
  ).current;

  return (
    <View style={[StyleSheet.absoluteFill]} {...panResponder.panHandlers} />
  );
});
GestureRecorder.displayName = 'GestureRecorderComponent';

export default GestureRecorder;
