import Button from '@/components/ui/Button';
import Text from '@/components/ui/Text';
import {
  changeTask,
  setChangeTaskLoading,
} from '@/redux/slices/clockings/functions';
import { AppDispatch, AppState } from '@/redux/store';
import ITask from '@/types/ITask.model';
import { getContrast } from '@/utils';
import { Camera } from 'expo-camera';
import * as ImageManipulator from 'expo-image-manipulator';
import moment from 'moment';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  camera: React.RefObject<Camera>;
  setModalVisible: (visible: boolean) => void;
}

const ShiftTasksList: React.FC<Props> = ({ camera, setModalVisible }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { theme, active_department } = useSelector(
    (state: AppState) => state.app
  );
  const { active_shift, active_clocking } = useSelector(
    (state: AppState) => state.clockings
  );

  const allTasks =
    active_shift && active_shift.tasks && active_clocking
      ? [
          ...active_clocking.tasks,
          ...active_shift.tasks!.filter(
            (t) => !active_clocking.tasks?.map((task) => task.id).includes(t.id)
          ),
        ]
      : [];

  const onPress = async (task: ITask, pending: boolean) => {
    dispatch(setChangeTaskLoading(true));
    let picture = null;
    if (active_department?.params.tablet_picture) {
      const image = await camera.current?.takePictureAsync({
        quality: 0.1,
        skipProcessing: true,
        base64: true,
      });
      if (image) {
        picture = await ImageManipulator.manipulateAsync(image.uri, [], {
          compress: 0.3,
          format: ImageManipulator.SaveFormat.JPEG,
          base64: true,
        });
      }
    }

    dispatch(
      changeTask({
        clocking_id: active_clocking!.id,
        departmentId: active_department!.id,
        task_id: task && !pending ? task.id : null,
        time: moment().unix().toString(),
        picture: picture ? picture?.base64 : undefined,
      })
    );

    setModalVisible(false);
  };

  return (
    <View style={styles.container}>
      {allTasks.map((task) => {
        const pendingTask = !!task.startdate && !task.enddate;
        const done = active_clocking
          ? active_clocking.tasks.find((t) => t.id == task.id)
          : false;
        return (
          <View style={styles.task}>
            <View>
              <Text color={getContrast(theme.secondary!)}>{task.name}</Text>
              {!done && (
                <Text color={getContrast(theme.secondary!)} size={scale(7)}>
                  {moment.unix(task.startdate).format('HH:mm')} -{' '}
                  {task.enddate
                    ? moment.unix(task.enddate).format('HH:mm')
                    : '__:__'}
                </Text>
              )}
            </View>
            {pendingTask || !done ? (
              <Button
                onPress={() => onPress(task, pendingTask)}
                value={pendingTask ? 'End' : 'Start'}
                color={pendingTask ? theme.error! : theme.primary!}
                textColor={getContrast(
                  pendingTask ? theme.error! : theme.primary!
                )}
                small
              />
            ) : (
              <Text color={getContrast(theme.secondary!)} size={scale(7)}>
                {moment.unix(task.startdate).format('HH:mm')} -{' '}
                {task.enddate
                  ? moment.unix(task.enddate).format('HH:mm')
                  : '__:__'}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: scale(5),
  },
  task: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: scale(5),
  },
});

export default ShiftTasksList;
