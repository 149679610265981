import { TIME_FORMAT } from "@/globals";
import { AppState } from "@redux/store";
import Text from "@ui/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  withTime?: boolean;
}

const Clock: React.FC<Props> = ({ withTime }) => {
  const { date } = useSelector((state: AppState) => state.app);
  const [clock, setClock] = useState(moment().format(TIME_FORMAT));

  useEffect(() => {
    const interval = setInterval(() => {
      setClock(moment().format(TIME_FORMAT));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <View style={styles.container}>
      <Text size={scale(10)}>{date}</Text>
      {withTime ? (
        <Text size={scale(35)} marginTop={scale(-10)}>
          {clock}
        </Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    alignSelf: "flex-start",
    top: scale(15),
    left: scale(20),
  },
});

export default React.memo(Clock);
