import IPoint from '@/types/IPoint.model';
import { forwardRef } from 'react';
import Svg, { Polyline } from 'react-native-svg';

const GesturePath = forwardRef((props: any, ref: any) => {
  const { paths, color, layout, width } = props;
  return (
    <Svg
      ref={ref}
      viewBox={`0 0 ${layout.width} ${layout.height}`}
      id='svg_element'
    >
      {paths.map((path: IPoint[], index: number) => {
        const points = path.map((p) => `${p.x},${p.y}`).join(' ');
        return (
          <Polyline
            key={`path_${index}`}
            points={points}
            fill='none'
            stroke={color}
            strokeWidth={width}
          />
        );
      })}
    </Svg>
  );
});
GesturePath.displayName = 'GesturePathComponent';

export default GesturePath;
