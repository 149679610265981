import { StyleSheet, View } from "react-native";
import React, { useCallback, useMemo } from "react";
import Text from "@components/ui/Text";
import { scale } from "react-native-size-matters";
import { getContrast } from "@/utils";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { AppDispatch, AppState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Camera } from "expo-camera";
import {
  changeTask,
  setChangeTaskLoading,
} from "@/redux/slices/clockings/functions";
import moment from "moment";
import * as ImageManipulator from "expo-image-manipulator";

interface Props {
  camera: React.RefObject<Camera>;
}

const SelectedTaskBadge: React.FC<Props> = ({ camera }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { active_department } = useSelector((state: AppState) => state.app);
  const { active_clocking, active_task, active_break } = useSelector(
    (state: AppState) => state.clockings
  );

  const onClearTask = useCallback(async () => {
    try {
      dispatch(setChangeTaskLoading(true));
      // setLastInteraction(Date.now());
      let picture = null;
      if (active_department?.params.tablet_picture) {
        const image = await camera.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      dispatch(
        changeTask({
          clocking_id: active_clocking!.id,
          departmentId: active_department!.id,
          task_id: null,
          time: moment().unix().toString(),
          picture: picture ? picture?.base64 : undefined,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [active_clocking, active_department, camera]);

  const styles = useMemo(() => {
    return StyleSheet.create({
      badge: {
        paddingVertical: scale(2),
        borderRadius: 5,
        marginHorizontal: scale(5),
        flexDirection: "row",
        alignItems: "center",
      },
      task_end_button: {
        backgroundColor: active_task
          ? getContrast(active_task.color!)
          : "transparent",
        marginLeft: scale(5),
        paddingHorizontal: scale(5),
        borderRadius: 5,
      },
      task_end_text: {
        fontSize: scale(8),
      },
      task_badge: {
        backgroundColor: active_task ? active_task.color! : "transparent",
        paddingHorizontal: scale(10),
        marginTop: scale(10),
      },
    });
  }, [active_task]);

  if (!active_task || active_break) return null;

  return (
    <View style={[styles.badge, styles.task_badge]}>
      <Text
        size={scale(10)}
        color={getContrast(active_task ? active_task.color! : "transparent")}
      >
        {active_task.name}
      </Text>
      <View style={styles.task_end_button}>
        <Button
          onPress={onClearTask}
          element={
            <Text
              color={getContrast(
                getContrast(active_task ? active_task.color! : "transparent")
              )}
              style={styles.task_end_text}
            >
              {t("GLOBAL.END")}
            </Text>
          }
          no_style
        />
      </View>
    </View>
  );
};

export default React.memo(SelectedTaskBadge);
