import {
  changeAttribute,
  setAttribute,
  setChangeAttributeLoading,
} from "@/redux/slices/clockings/functions";
import IAttribute from "@/types/IAttribute.model";
import { getContrast } from "@/utils";
import { AppDispatch, AppState } from "@redux/store";
import Text from "@ui/Text";
import { Camera } from "expo-camera";
import moment from "moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import * as ImageManipulator from "expo-image-manipulator";

interface Props {
  from: "home" | "profile";
  attribute: IAttribute | null;
  setModalVisible: (visible: boolean) => void;
  camera: React.RefObject<Camera>;
}

const AttributeItem: React.FC<Props> = ({
  from,
  attribute,
  camera,
  setModalVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { theme, active_department } = useSelector(
    (state: AppState) => state.app
  );
  const { active_clocking, active_attribute } = useSelector(
    (state: AppState) => state.clockings
  );
  const is_active = active_attribute?.id === attribute?.id;

  const onPress = useCallback(async () => {
    if (from == "home") {
      dispatch(setAttribute(attribute));
      setModalVisible(false);
    }
    if (from == "profile") {
      try {
        dispatch(setChangeAttributeLoading(true));
        let picture = null;
        if (active_department?.params.tablet_picture) {
          const image = await camera.current?.takePictureAsync({
            quality: 0.1,
            skipProcessing: true,
            base64: true,
          });
          if (image) {
            picture = await ImageManipulator.manipulateAsync(image.uri, [], {
              compress: 0.3,
              format: ImageManipulator.SaveFormat.JPEG,
              base64: true,
            });
          }
        }

        dispatch(
          changeAttribute({
            clocking_id: active_clocking!.id,
            departmentId: active_department!.id,
            attribute_id: attribute ? attribute.id : null,
            time: moment().unix().toString(),
            picture: picture ? picture?.base64 : undefined,
          })
        );
      } catch (err) {
        console.log(err);
      }
      setModalVisible(false);
    }
  }, []);

  return (
    <TouchableOpacity onPress={onPress}>
      <Text
        size={scale(10)}
        color={is_active ? theme.error! : getContrast(theme.secondary!)}
      >
        {attribute ? attribute.name : t("GLOBAL.NO_ATTRIBUTE")}
      </Text>
    </TouchableOpacity>
  );
};

export default React.memo(
  AttributeItem,
  (prev, next) => prev.attribute?.id === next.attribute?.id
);
