import { ParamList } from '@/globals';
import { store } from '@/redux/store';
import { IDepartmentParams } from '@/types/IDepartmentParams.model';
import IUser from '@/types/IUser.model';
import api from '@api';
import IPosData from '@models/IPosData.model';
import ITheme from '@models/ITheme.model';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import { actions as clockingActions } from '../clockings/clockingsSlice';
import { actions } from './appSlice';

type Dispatch = typeof store.dispatch;

// Register tablet
export const registerTablet =
  (api_key: string | null, notify: boolean = false) =>
  async (dispatch: Dispatch) => {
    const key = api_key?.split('#')[0];
    const section = api_key?.split('#')[1];
    const section_id = section ? section.split('=')[1] : null;
    if (notify) {
      await api.post('/register', { apiKey: key, sectionId: section_id });
    }
    try {
      if (key) {
        if (Platform.OS !== 'web') {
          await SecureStore.setItemAsync('api_key', api_key, {
            keychainAccessible: SecureStore.ALWAYS,
          });
        } else {
          await AsyncStorage.setItem('api_key', api_key);
        }
      }
      dispatch(actions.registerTablet({ key, section_id }));
    } catch (err) {
      throw err;
    }
  };

// Get theme
export const getTheme = () => async (dispatch: Dispatch) => {
  dispatch(actions.setLoading(true));
  const { data }: { data: ITheme } = await api.get('/theme');
  dispatch(actions.setTheme(data));
  dispatch(actions.setLoading(false));
};

// Set theme
export const setTheme = (data: ITheme) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    dispatch(actions.setTheme(data));
    dispatch(actions.setLoading(false));
  } catch (err) {
    console.log(err);
  }
};

// Change department params
export const setDepartmentParams =
  (data: IDepartmentParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setLoading(true));
      dispatch(actions.setDepartmentParams(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

// Change department params
export const setDepartment =
  (data: IDepartmentParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setLoading(true));
      dispatch(actions.setActiveDepartment(data));
      dispatch(actions.setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

// Get POS data
export const getPosData = () => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    const { data }: { data: IPosData } = await api.post('/login');
    dispatch(actions.getPosData(data));
    dispatch(actions.setLoading(false));
  } catch (err) {
    throw err;
  }
};

// Get active user data
export const getUser =
  (
    user: IUser,
    department_id: string,
    navigation?: NativeStackNavigationProp<ParamList>
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(clockingActions.setLoading(true));
    try {
      const { data }: { data: IUser } = await api.get(`/get-user/${user.id}`, {
        params: {
          departmentId: department_id,
        },
      });

      if (data.clocking) {
        const { breaks } = data.clocking;
        if (breaks.length > 0) {
          const last_break = breaks[breaks.length - 1];
          if (last_break && !last_break.enddate) {
            dispatch(clockingActions.setBreak(last_break));
          }
        }
      }

      dispatch(actions.getUser(data));
      dispatch(clockingActions.setUser({ ...user, ...data }));
      dispatch(clockingActions.setClocking(data.clocking));
      dispatch(clockingActions.setLoading(false));

      if (navigation) {
        if (data.clocking) {
          navigation?.navigate('Profile');
        } else {
          navigation?.navigate('Home');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

// Update user
export const updateUser = (user: IUser) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.updateUser(user));
  } catch (err) {
    console.log(err);
  }
};

// Set loading
export const setLoading = (loading: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setLoading(loading));
  } catch (err) {
    console.log(err);
  }
};

// Set error
export const setError = (error: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setError(error));
  } catch (err) {
    console.log(err);
  }
};

// Clear error
export const clearError = () => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.clearError());
  } catch (err) {
    console.log(err);
  }
};

// Reset whole app
export const reset = () => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.reset());
  } catch (err) {
    throw err;
  }
};
