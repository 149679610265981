import { View, Text } from "react-native";
import React, { useMemo } from "react";
import Button from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import { scale } from "react-native-size-matters";

interface Props {
  setModalSelectAttributeVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeAttributeButton: React.FC<Props> = ({
  setModalSelectAttributeVisible,
}) => {
  const { t } = useTranslation();
  const { resources, theme } = useSelector((state: AppState) => state.app);
  const { active_shift, change_attribute_loading } = useSelector(
    (state: AppState) => state.clockings
  );

  const onAttributePress = () => {
    // setLastInteraction(Date.now());
    setModalSelectAttributeVisible(true);
  };

  const show_attributes = useMemo(() => {
    return !active_shift && resources && resources.length > 0;
  }, [active_shift, resources]);

  if (!show_attributes) return null;

  return (
    <Button
      value={t("GLOBAL.ATTRIBUTE")}
      color={getContrast(theme.background!)}
      textColor={getContrast(getContrast(theme.background!))}
      onPress={onAttributePress}
      style={{
        flex: 1,
        marginLeft: scale(2),
        marginTop: scale(5),
      }}
      loading={change_attribute_loading}
    />
  );
};

export default React.memo(ChangeAttributeButton);
