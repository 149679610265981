import { AppState } from '@/redux/store';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import Svg, { Circle } from 'react-native-svg';
import { useSelector } from 'react-redux';

interface Props {
  active?: boolean;
}

const Dot: React.FC<Props> = ({ active }) => {
  const { theme } = useSelector((state: AppState) => state.app);
  return (
    <View style={styles.container}>
      <Svg
        width={scale(10)}
        height={scale(10)}
        viewBox='-2 -2 30 30'
        fill='none'
      >
        <Circle
          cx='13'
          cy='13'
          r='13'
          fill={active ? theme.primary! : 'none'}
          stroke={active ? theme.primary! : theme.contrast!}
          strokeWidth={2}
        />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: scale(5),
  },
});

export default Dot;
