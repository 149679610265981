import React from "react";
import Text from "@components/ui/Text";
import { scale } from "react-native-size-matters";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_SHORT, TIME_FORMAT } from "@/globals";
import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";

interface Props {}

const StartedDate: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { active_clocking } = useSelector((state: AppState) => state.clockings);

  if (!active_clocking) return null;

  const { startdate } = active_clocking;

  return (
    <Text size={scale(10)}>
      {t("GLOBAL.STARTED_DATE")}{" "}
      {moment.utc(startdate * 1000).format(DATE_FORMAT_SHORT)} {t("GLOBAL.AT")}{" "}
      {moment.utc(startdate * 1000).format(TIME_FORMAT)}
    </Text>
  );
};

export default React.memo(StartedDate);
