import { AppState } from '@/redux/store';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import DefaultContainer from '../default/DefaultContainer';

interface Props {}

const LoadingScreen: React.FC<Props> = () => {
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <DefaultContainer>
      <ActivityIndicator size='large' color={theme.primary!} />
    </DefaultContainer>
  );
};

export default LoadingScreen;
