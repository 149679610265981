import { View, Text } from "react-native";
import React, { useMemo } from "react";
import Button from "@/components/ui/Button";
import CloseCross from "@/components/icons/CloseCross";
import DropdownArrow from "@/components/icons/DropdownArrow";
import { scale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch, AppState } from "@/redux/store";
import { setTask } from "@/redux/slices/clockings/functions";

interface Props {
  onTaskPress: () => void;
}

const SelectTaskButton: React.FC<Props> = ({ onTaskPress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { active_department, tasks } = useSelector(
    (state: AppState) => state.app
  );
  const { active_shift, active_task } = useSelector(
    (state: AppState) => state.clockings
  );

  const show_tasks = useMemo(() => {
    return (
      (active_shift ||
        (!active_shift && active_department?.params.freeclockin)) &&
      tasks &&
      tasks?.length
    );
  }, [active_shift, active_department, tasks]);

  if (!show_tasks) return null;

  const clearActiveTask = () => {
    dispatch(setTask(null));
  };

  return (
    <Button
      value={active_task ? active_task.name : t("GLOBAL.SELECT_TASK")}
      width={width / 2.5}
      icon={
        active_task ? (
          <Button
            value=""
            icon={<CloseCross color="#FFFFFF" />}
            onPress={clearActiveTask}
            no_style
          />
        ) : (
          <DropdownArrow />
        )
      }
      onPress={onTaskPress}
      justifyContent="space-between"
      marginTop={scale(10)}
      marginHorizontal={scale(5)}
    />
  );
};

export default SelectTaskButton;
