import { PIN_CODE_LENGTH } from '@/globals';
import React from 'react';
import { View } from 'react-native';
import Dot from './Dot';

interface Props {
  pin_code: string;
}

const PinCodeLength: React.FC<Props> = ({ pin_code }) => {
  return (
    <>
      {[...Array(PIN_CODE_LENGTH)].map((_, i) => (
        <Dot key={i} active={i < pin_code.length} />
      ))}
    </>
  );
};

export default PinCodeLength;
