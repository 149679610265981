export default (script: any) =>
  `<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no">
  <meta name="apple-mobile-web-app-capable" content="yes">
  <meta name="apple-mobile-web-app-status-bar-style" content="black">

  <style>
    body {
      font-family: Helvetica, Sans-Serif;
    
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      margin:0;
      overflow:hidden;
    }
    body,html {
      width: 100%;
      height: 250px;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    .rotated-false {
      width: 100%;
      height: 100%;
    }
    .m-signature-pad {
      font-size: 10px;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
    }
    
    .m-signature-pad--body
      canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
      }
    
    .m-signature-pad--footer {

      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .m-signature-pad--footer
      .description {
        color: #C3C3C3;
        text-align: center;
        font-size: 1.2em;
      }
    
    .m-signature-pad--footer
      .button {
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #FFF;
        border: 1px solid transparent;
        border-radius: 4px;
        outline: none;
        box-shadow: none;
      }
    
    .m-signature-pad--footer
      .button.clear {
      }
    
    .m-signature-pad--footer
      .button.save {
      }
    
    @media screen and (max-width: 1024px) {
      .m-signature-pad {
        width: 100%;
        height: 100%;
      }
      #github {
        display: none;
      }
    }
    
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      .m-signature-pad {
      }
    }
    
    @media screen and (max-height: 320px) {
      .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
      }
      .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
      }
      .m-signature-pad--footer
        .description {
          font-size: 1em;
        }
    }
    <%style%>
    </style>
</head>
<body onselectstart="return false">
  <div class="rotated-<%orientation%>">
    <div id="signature-pad" class="m-signature-pad">
      <div class="m-signature-pad--body">
        <canvas></canvas>
      </div>
    </div>
  </div>
  <script>
    ${script}
  </script>
</body>
</html>`;
