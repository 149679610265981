import { AppState } from '@/redux/store';
import React from 'react';
import { scale } from 'react-native-size-matters';
import Svg, { Path } from 'react-native-svg';
import { useSelector } from 'react-redux';

interface Props {}

const Begin: React.FC<Props> = () => {
  const { theme } = useSelector((state: AppState) => state.app);

  return (
    <Svg width={scale(10)} height={scale(10)} viewBox='0 0 35 35' fill='none'>
      <Path
        d='M17.5 32.9168C26.0144 32.9168 32.9167 26.0146 32.9167 17.5002C32.9167 8.98577 26.0144 2.0835 17.5 2.0835C8.98565 2.0835 2.08337 8.98577 2.08337 17.5002C2.08337 26.0146 8.98565 32.9168 17.5 32.9168Z'
        stroke={theme.primary!}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M22.125 23.6667L18.4034 19.9451C17.8251 19.367 17.5002 18.5829 17.5 17.7652V8.25'
        stroke={theme.primary!}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default Begin;
