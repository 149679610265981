import IShift from '@/types/IShift.model';
import ISkill from '@/types/ISkill.model';
import { IBreak } from '@models/IBreak.model';
import { IClocking } from '@models/IClocking.model';
import ISection from '@models/ISection.model';
import ITask from '@models/ITask.model';
import IUser from '@models/IUser.model';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IClockingsState = {
  active_user: null,
  active_clocking: null,
  active_shift: null,
  active_break: null,
  active_section: null,
  active_skill: null,
  active_task: null,
  active_attribute: null,
  clock_loading: false,
  break_loading: false,
  change_section_loading: false,
  change_skill_loading: false,
  change_task_loading: false,
  change_attribute_loading: false,
  sign_document_loading: false,
  loading: false,
  error: false,
};

const clockingsSlice = createSlice({
  name: 'clockings',
  initialState,
  reducers: {
    clockingIn: (state, { payload }) => {
      state.active_clocking = payload;
    },
    clockingOut: (state) => {
      state.active_clocking = null;
      state.active_break = null;
    },
    breakIn: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_break = payload.breaks[payload.breaks.length - 1];
    },
    breakOut: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_break = null;
    },
    changeSection: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_section = payload.section;
    },
    changeSkill: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_skill = payload.skill;

      // if (payload.section) {
      //   state.active_section = payload.section;
      // }
      // if (payload.task) {
      //   state.active_task = payload.task;
      // }
      // if (payload.attribute) {
      //   state.active_attribute = payload.attribute;
      // }
    },
    changeTask: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_task = payload.task;

      // if (payload.section) {
      //   state.active_section = payload.section;
      // }
      // if (payload.skill) {
      //   state.active_skill = payload.skill;
      // }
      // if (payload.attribute) {
      //   state.active_attribute = payload.attribute;
      // }
    },
    changeAttribute: (state, { payload }) => {
      state.active_clocking = payload;
      state.active_attribute = payload.attribute;

      // if (payload.section) {
      //   state.active_section = payload.section;
      // }
      // if (payload.skill) {
      //   state.active_skill = payload.skill;
      // }
      // if (payload.task) {
      //   state.active_task = payload.task;
      // }
    },
    setUser: (state, { payload }) => {
      state.active_user = payload;
    },
    setClocking: (state, { payload }) => {
      state.active_clocking = payload;
    },
    setSection: (state, { payload }) => {
      state.active_section = payload;
    },
    setSkill: (state, { payload }) => {
      state.active_skill = payload;
    },
    setTask: (state, { payload }) => {
      state.active_task = payload;
    },
    setAttribute: (state, { payload }) => {
      state.active_attribute = payload;
    },
    setBreak: (state, { payload }) => {
      state.active_break = payload;
    },
    setShift: (state, { payload }) => {
      state.active_shift = payload;
    },
    setClockLoading: (state, { payload }) => {
      state.clock_loading = payload;
    },
    setBreakLoading: (state, { payload }) => {
      state.break_loading = payload;
    },
    setChangeSectionLoading: (state, { payload }) => {
      state.change_section_loading = payload;
    },
    setChangeSkillLoading: (state, { payload }) => {
      state.change_skill_loading = payload;
    },
    setChangeTaskLoading: (state, { payload }) => {
      state.change_task_loading = payload;
    },
    setChangeAttributeLoading: (state, { payload }) => {
      state.change_attribute_loading = payload;
    },
    setSignDocumentLoading: (state, { payload }) => {
      state.sign_document_loading = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    reset: (state) => {
      state.active_user = null;
      state.active_clocking = null;
      state.active_break = null;
      state.active_section = null;
      state.active_skill = null;
      state.active_task = null;
      state.active_attribute = null;
      state.change_task_loading = false;
      state.change_section_loading = false;
      state.sign_document_loading = false;
      state.loading = false;
      state.error = false;
    },
  },
});

export const actions = clockingsSlice.actions;
export default clockingsSlice.reducer;

interface IClockingsState {
  active_user: IUser | null;
  active_clocking: IClocking | null;
  active_shift: IShift | null;
  active_break: IBreak | null;
  active_section: ISection | null;
  active_skill: ISkill | null;
  active_task: ITask | null;
  active_attribute: ITask | null;
  clock_loading: boolean;
  break_loading: boolean;
  change_section_loading: boolean;
  change_skill_loading: boolean;
  change_task_loading: boolean;
  change_attribute_loading: boolean;
  sign_document_loading: boolean;
  loading: boolean;
  error: boolean;
}
