import moment, { Duration } from 'moment';
import { TFunction } from 'react-i18next';

export const ejectError = (api: any, error: any) => {
  return api.rejectWithValue(
    (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
  );
};

export const getErrorData = (api: any, error: any) => {
  return api.rejectWithValue(
    Object.values(error.response.data.errors).flat(1) as string[]
  );
};

export const humanize = (
  duration: Duration,
  t: TFunction<'translation', undefined>
) => {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const hours_text = t(`GLOBAL.HOUR${hours == 1 ? '' : 'S'}`);
  const minutes_text = t(`GLOBAL.MINUTE${minutes == 1 ? '' : 'S'}`);
  const seconds_text = t(`GLOBAL.SECOND${seconds == 1 ? '' : 'S'}`);

  if (hours > 0 && minutes == 0 && seconds == 0) {
    return `${hours} ${hours_text}`;
  }

  if (hours > 0 && minutes > 0 && seconds == 0) {
    return `${hours} ${hours_text} ${t(
      'GLOBAL.AND'
    )} ${minutes} ${minutes_text}`;
  }

  if (hours > 0 && minutes == 0 && seconds > 0) {
    return `${hours} ${hours_text} ${t(
      'GLOBAL.AND'
    )} ${seconds} ${seconds_text}`;
  }

  if (hours > 0 && minutes > 0 && seconds > 0) {
    return `${hours} ${hours_text} ${minutes} ${minutes_text} ${t(
      'GLOBAL.AND'
    )} ${seconds} ${seconds_text}`;
  }

  if (hours == 0 && minutes > 0 && seconds == 0) {
    return `${minutes} ${minutes_text}`;
  }

  if (hours == 0 && minutes > 0 && seconds > 0) {
    return `${minutes} ${minutes_text} ${t(
      'GLOBAL.AND'
    )} ${seconds} ${seconds_text}`;
  }
  if (hours == 0 && minutes == 0 && seconds > 0) {
    return `${seconds} ${seconds_text}`;
  }
};

export const lighten = (hex: string, amt: number) => {
  if (hex === 'black') {
    hex = '#000001';
  }
  if (hex === 'white') {
    hex = '#ffffff';
  }
  var usePound = false;

  if (hex && hex[0] == '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  var num = parseInt(hex, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const getContrast = (hex: string) => {
  if (hex === 'transparent') {
    hex = '#000';
  }

  if (hex === 'white') {
    hex = '#ffffff';
  }

  if (hex === 'black') {
    hex = '#000000';
  }

  if (hex) {
    hex = hex.replace('#', '');
    var r = parseInt(hex.substr(0, 2), 16);
    var g = parseInt(hex.substr(2, 2), 16);
    var b = parseInt(hex.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  return 'black';
};

export const localTimestampToUtc = (timestamp: number) => {
  return moment.utc(moment.unix(timestamp)).unix();
};
