import Close from '@/components/Close';
import Time from '@/components/Time';
import { setSection, setSkill } from '@/redux/slices/clockings/functions';
import ModalSelectSection from '@/screens/home/components/sections/ModalSelectSection';
import Clock from '@components/Clock';
import { DATE_FORMAT_FULL, ParamList } from '@globals';
import CompanyLogo from '@icons/CompanyLogo';
import IShift from '@models/IShift.model';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AppDispatch, AppState } from '@redux/store';
import DefaultContainer from '@screens/default/DefaultContainer';
import Text from '@ui/Text';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { scale } from 'react-native-size-matters';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from './components/Countdown';
import HelloText from './components/HelloText';
import PlannedShiftText from './components/PlannedShiftText';
import StartButton from './components/StartButton';
import ModalSelectAttribute from './components/attributes/ModalSelectAttribute';
import SelectAttributeButton from './components/attributes/SelectAttributeButton';
import SelectSectionButton from './components/sections/SelectSectionButton';
import ModalSelectSkill from './components/skills/ModalSelectSkill';
import SelectSkillButton from './components/skills/SelectSkillButton';
import ModalSelectTask from './components/tasks/ModalSelectTask';
import SelectTaskButton from './components/tasks/SelectTaskButton';
import TasksList from './components/tasks/TasksList';

interface Props {}

interface Props extends NativeStackScreenProps<ParamList, 'Home'> {}

const HomeScreen: React.FC<Props> = ({ route, navigation }) => {
  const { cameraRef } = route.params as any;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useSelector((state: AppState) => state.device);
  const { sections, tasks, skills, active_department, theme, section_id } =
    useSelector((state: AppState) => state.app);
  const { active_user } = useSelector((state: AppState) => state.clockings);
  const [modalSelectSectionVisible, setModalSelectSectionVisible] =
    useState<boolean>(false);
  const [modalSelectSkillVisible, setModalSelectSkillVisible] =
    useState<boolean>(false);
  const [modalSelectTaskVisible, setModalSelectTaskVisible] =
    useState<boolean>(false);
  const [modalSelectAttributeVisible, setModalSelectAttributeVisible] =
    useState<boolean>(false);
  const [sectionError, setSectionError] = useState<boolean>(false);

  const active_shift = active_user?.today_shifts?.find((shift: IShift) => {
    const now = moment(moment().format(DATE_FORMAT_FULL));

    const start = moment(
      moment
        .utc(shift.startdate * 1000)
        .subtract(2, 'hour')
        .format(DATE_FORMAT_FULL)
    );
    const end = moment(
      moment.utc(shift.enddate * 1000).format(DATE_FORMAT_FULL)
    );

    return now.isBetween(start, end);
  });

  useEffect(() => {
    if (active_user?.contract_to_sign) {
      navigation.navigate('Signature');
    }

    if (active_user) {
      const user_sections = active_user.sections;
      const user_skills = active_user.skills;
      const user_tasks = tasks;
      if (user_sections?.length == 1) {
        const section = sections?.find(
          (section) => section.id === user_sections[0]
        );
        if (section) {
          dispatch(setSection(section));
        }
      }
      if (
        user_skills?.length == 1 &&
        active_department &&
        active_department.id !== '2455' &&
        !active_shift
      ) {
        const skill = skills?.find((skill) => skill.id === user_skills[0]);
        if (skill) {
          dispatch(setSkill(skill));
        }
      }
    }
  }, [active_user, active_department, active_shift]);

  const is_shift = active_shift?.shift_type == 1;

  useEffect(() => {
    if (!is_shift) return;

    if (active_shift.section) {
      dispatch(setSection(active_shift.section));
    }

    if (active_shift.skills && active_shift.skills.length == 1) {
      dispatch(setSkill(active_shift.skills[0]));
    }
  }, [active_shift]);

  const active_shift_start_moment = useMemo(() => {
    return active_shift?.startdate
      ? moment.utc(active_shift.startdate * 1000)
      : null;
  }, [active_shift]);

  const active_shift_end_moment = useMemo(() => {
    return active_shift?.enddate
      ? moment.utc(active_shift.enddate * 1000)
      : null;
  }, [active_shift]);

  const setSectionErrorCallback = useCallback(
    (value: React.SetStateAction<boolean>) => {
      setSectionError(value);
    },
    []
  );

  const onSectionPress = useCallback(() => {
    setModalSelectSectionVisible(!modalSelectSectionVisible);
  }, []);

  const onSkillPress = useCallback(() => {
    setModalSelectSkillVisible(!modalSelectSkillVisible);
  }, []);

  const onTaskPress = useCallback(() => {
    setModalSelectTaskVisible(!modalSelectTaskVisible);
  }, []);

  const onAttributePress = useCallback(() => {
    setModalSelectAttributeVisible(!modalSelectAttributeVisible);
  }, []);

  return (
    <>
      <ModalSelectSection
        from='home'
        visible={modalSelectSectionVisible}
        setVisible={setModalSelectSectionVisible}
        camera={cameraRef}
      />
      <ModalSelectSkill
        active_shift={active_shift}
        from='home'
        visible={modalSelectSkillVisible}
        setVisible={setModalSelectSkillVisible}
        camera={cameraRef}
      />
      <ModalSelectTask
        from='home'
        visible={modalSelectTaskVisible}
        setVisible={setModalSelectTaskVisible}
        camera={cameraRef}
      />
      <ModalSelectAttribute
        from='home'
        visible={modalSelectAttributeVisible}
        setVisible={setModalSelectAttributeVisible}
        camera={cameraRef}
      />
      <DefaultContainer withCircle>
        <Clock />
        <View style={styles.main_container}>
          <HelloText />
          {active_shift &&
          is_shift &&
          active_shift_start_moment &&
          active_shift_end_moment ? (
            <PlannedShiftText
              active_shift_start_moment={active_shift_start_moment}
              active_shift_end_moment={active_shift_end_moment}
            />
          ) : null}
          <Time />
          {active_shift && active_shift.tasks.length > 0 && (
            <TasksList active_shift={active_shift} />
          )}
          {active_shift ? (
            <>
              {is_shift ? (
                <Countdown active_shift={active_shift} />
              ) : (
                <Text size={scale(10)} error center>
                  {t('HOME.CANNOT_CHECK_IN')}
                </Text>
              )}
            </>
          ) : (
            <>
              {!active_department?.params.freeclockin ? (
                <Text size={scale(10)} error center>
                  {t('HOME.CANNOT_FREE_CLOCKING')}
                </Text>
              ) : null}
            </>
          )}
          <View style={styles.row}>
            {!section_id ? (
              <SelectSectionButton
                sectionError={sectionError}
                active_shift={active_shift}
                setSectionError={setSectionError}
                onSectionPress={onSectionPress}
              />
            ) : null}
            <SelectSkillButton
              active_shift={active_shift}
              onSkillPress={onSkillPress}
            />
            {!is_shift && <SelectTaskButton onTaskPress={onTaskPress} />}
            <SelectAttributeButton
              active_shift={active_shift}
              onAttributePress={onAttributePress}
            />
          </View>
          <StartButton
            camera={cameraRef}
            setSectionError={setSectionErrorCallback}
            navigation={navigation}
            route={route}
            active_shift={active_shift}
          />
        </View>
        <CompanyLogo bottom custom={theme.logo} />
        <Close />
      </DefaultContainer>
    </>
  );
};

const styles = StyleSheet.create({
  main_container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

export default HomeScreen;
