import CloseCross from "@/components/icons/CloseCross";
import { AppState } from "@/redux/store";
import React from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { scale } from "react-native-size-matters";
import { WebView } from "react-native-webview";
import { useSelector } from "react-redux";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const ContractModal: React.FC<Props> = ({ visible, setVisible }) => {
  const { width, height } = useSelector((state: AppState) => state.device);
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_user } = useSelector((state: AppState) => state.clockings);

  const onBackdropPress = () => {
    setVisible(!visible);
  };

  const onCross = () => {
    setVisible(!visible);
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: "#F5FEF8",
      paddingVertical: scale(10),
      paddingHorizontal: scale(15),
      borderRadius: 5,
      width: width / 1.1,
      height: height / 1.3,
      alignSelf: "center",
    },
    cross: {
      backgroundColor: theme.primary!,
      alignSelf: "flex-end",
      borderRadius: 50,
      marginBottom: scale(10),
    },
  });

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <View style={styles.container}>
        <TouchableOpacity style={styles.cross} onPress={onCross}>
          <CloseCross />
        </TouchableOpacity>
        {active_user?.contract_to_sign?.data?.html ? (
          <>
            {Platform.OS === "web" ? (
              <iframe
                srcDoc={active_user!.contract_to_sign!.data!.html}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <WebView
                originWhitelist={["*"]}
                source={{
                  html: active_user!.contract_to_sign!.data!.html,
                }}
              />
            )}
          </>
        ) : null}
      </View>
    </Modal>
  );
};

export default ContractModal;
