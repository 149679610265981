import { TIME_FORMAT } from "@/globals";
import {
  DATE_FORMAT_FULL,
  DATE_FORMAT_SHORT,
  TIME_FORMAT_FULL,
  UTC_OFFSET,
} from "@/globals";
import { AppState } from "@/redux/store";
import { getContrast, humanize, lighten } from "@/utils";
import Text from "@ui/Text";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalTasksHistory: React.FC<Props> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme } = useSelector((state: AppState) => state.app);
  const { active_clocking } = useSelector((state: AppState) => state.clockings);

  const onBackdropPress = () => {
    setVisible(false);
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.secondary!,
      padding: scale(15),
      borderRadius: 5,
      width: width / 2,
      alignSelf: "center",
    },
    title: {
      marginBottom: 10,
    },
    item: {
      backgroundColor: lighten(theme.secondary!, 15),
      padding: 15,
      marginVertical: 5,
      borderRadius: 5,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    task_container: {
      paddingHorizontal: scale(5),
      borderRadius: 5,
    },
    active_task_container: {
      flexDirection: "row",
      alignItems: "center",
    },
    pending_container: {
      flexDirection: "row",
      alignItems: "center",
    },
    pending_badge: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      backgroundColor: theme.disabled_light!,
      color: getContrast(theme.disabled_light!),
      borderRadius: 5,
    },
  });

  const now = moment();
  const diff = active_clocking
    ? moment(now.format(DATE_FORMAT_FULL)).diff(
        moment(
          moment
            .utc(active_clocking?.startdate! * 1000)
            .format(DATE_FORMAT_FULL)
        )
      )
    : null;
  const duration = active_clocking
    ? moment.duration(diff, "milliseconds")
    : null;

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <View style={styles.container}>
        <Text style={styles.title} color={getContrast(theme.secondary!)}>
          {t("PROFILE.TASKS_HISTORY")}
        </Text>
        <ScrollView>
          {active_clocking?.tasks &&
            [...active_clocking?.tasks!]
              .reverse()
              .map(({ id, startdate, enddate, name, color }) => {
                const start_moment = moment.utc(startdate * 1000);
                let start_diff = moment(now.format(DATE_FORMAT_FULL)).diff(
                  moment(moment.utc(startdate * 1000).format(DATE_FORMAT_FULL))
                );
                let end_diff = enddate
                  ? moment(
                      moment(
                        moment.utc(enddate * 1000).format(DATE_FORMAT_FULL)
                      )
                    ).diff(start_moment.format(DATE_FORMAT_FULL))
                  : 0;

                let duration;
                if (enddate) {
                  duration = humanize(moment.duration(end_diff), t);
                } else {
                  duration = humanize(moment.duration(start_diff), t);
                }

                return (
                  <View key={id} style={[styles.item]}>
                    {enddate ? (
                      <>
                        <View
                          style={[
                            { backgroundColor: color! },
                            styles.task_container,
                          ]}
                        >
                          <Text size={scale(7)} color={getContrast(color!)}>
                            {name}
                          </Text>
                        </View>
                        <Text
                          size={scale(7)}
                          color={getContrast(theme.secondary!)}
                        >
                          {start_moment.format(TIME_FORMAT)} -{" "}
                          {moment.utc(enddate * 1000).format(TIME_FORMAT)}{" "}
                          {duration ? `(${duration})` : null}
                        </Text>
                      </>
                    ) : (
                      <>
                        <View
                          style={[
                            { backgroundColor: color! },
                            styles.task_container,
                            styles.active_task_container,
                          ]}
                        >
                          <Text size={scale(7)} color={getContrast(color!)}>
                            {name}
                          </Text>
                          <Text size={scale(5)} color={theme.disabled_light!}>
                            {" "}
                            ({duration})
                          </Text>
                        </View>
                        <View style={styles.pending_container}>
                          <Text
                            size={scale(7)}
                            color={getContrast(theme.secondary!)}
                          >
                            {start_moment.format(TIME_FORMAT)} -{" "}
                          </Text>
                          <Text
                            size={scale(7)}
                            color={getContrast(theme.secondary!)}
                            style={styles.pending_badge}
                          >
                            {t("GLOBAL.PENDING")}
                          </Text>
                        </View>
                      </>
                    )}
                  </View>
                );
              })}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default React.memo(ModalTasksHistory);
