import { DATE_FORMAT_FULL, SHYFTER, TIME_FORMAT_FULL } from "@/globals";
import { AppState } from "@/redux/store";
import { IBreak } from "@/types/IBreak.model";
import Text from "@ui/Text";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {}

const WorkTime: React.FC<Props> = () => {
  const { active_department } = useSelector((state: AppState) => state.app);
  const { active_clocking, active_break } = useSelector(
    (state: AppState) => state.clockings
  );
  const [realWorkingDuration, setRealWorkingDuration] =
    useState<moment.Moment>();
  const [activeBreakDuration, setActiveBreakDuration] =
    useState<moment.Moment>();

  const { startdate: active_clocking_start } = active_clocking || {};

  useEffect(() => {
    compute();
    const interval = setInterval(() => {
      compute();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [active_clocking, active_break]);

  const compute = () => {
    if (active_clocking) {
      const now = moment().tz(active_department!.timezone);

      const clocking_diff = moment(now.format(DATE_FORMAT_FULL)).diff(
        moment(
          moment.utc(active_clocking_start! * 1000).format(DATE_FORMAT_FULL)
        )
      );

      const clocking_duration = moment.utc(
        moment.duration(clocking_diff, "milliseconds").asMilliseconds()
      );

      const breaks_duration = active_clocking?.breaks.reduce(
        (total: number, currentValue: IBreak) => {
          const { startdate, enddate } = currentValue;
          const start = moment(startdate);
          if (enddate) {
            const end = moment(enddate);
            const break_duration = moment
              .duration(end.diff(start))
              .asMilliseconds();
            return total + break_duration;
          } else {
            const break_duration = moment
              .duration(now.diff(start))
              .asMilliseconds();
            return total + break_duration;
          }
        },
        0
      );

      if (active_break) {
        const break_diff = moment(now.format(DATE_FORMAT_FULL)).diff(
          moment(
            moment.utc(active_break.startdate! * 1000).format(DATE_FORMAT_FULL)
          )
        );

        const break_duration = moment.utc(
          moment.duration(break_diff, "milliseconds").asMilliseconds()
        );

        setActiveBreakDuration(break_duration);
      }

      if (active_department?.params.paid_clocking_break) {
        setRealWorkingDuration(clocking_duration);
      } else {
        setRealWorkingDuration(
          clocking_duration.subtract(breaks_duration, "seconds")
        );
      }
    }
  };

  return (
    <>
      {active_break ? (
        <Text semi size={scale(40)} color={SHYFTER.theme.error}>
          {moment(activeBreakDuration).utc().format(TIME_FORMAT_FULL)}
        </Text>
      ) : (
        <>
          {realWorkingDuration ? (
            <Text semi size={scale(40)}>
              {realWorkingDuration.format(TIME_FORMAT_FULL)}
            </Text>
          ) : null}
        </>
      )}
    </>
  );
};

export default WorkTime;
