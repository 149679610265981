import { StyleSheet, View } from "react-native";
import React, { useCallback, useMemo } from "react";
import Text from "@components/ui/Text";
import { scale } from "react-native-size-matters";
import { getContrast } from "@/utils";
import Button from "@/components/ui/Button";
import CloseCross from "@/components/icons/CloseCross";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/redux/store";
import { Camera } from "expo-camera";
import { changeAttribute } from "@/redux/slices/clockings/functions";
import moment from "moment";
import { setChangeAttributeLoading } from "@/redux/slices/clockings/functions";
import * as ImageManipulator from "expo-image-manipulator";

interface Props {
  camera: React.RefObject<Camera>;
}

const SelectedAttributeBadge: React.FC<Props> = ({ camera }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { active_department } = useSelector((state: AppState) => state.app);
  const { active_clocking, active_attribute, active_break } = useSelector(
    (state: AppState) => state.clockings
  );

  const onClearAttribute = useCallback(async () => {
    try {
      dispatch(setChangeAttributeLoading(true));
      // setLastInteraction(Date.now());
      let picture = null;
      if (active_department?.params.tablet_picture) {
        const image = await camera.current?.takePictureAsync({
          quality: 0.1,
          skipProcessing: true,
          base64: true,
        });
        if (image) {
          picture = await ImageManipulator.manipulateAsync(image.uri, [], {
            compress: 0.3,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          });
        }
      }

      dispatch(
        changeAttribute({
          clocking_id: active_clocking!.id,
          departmentId: active_department!.id,
          attribute_id: null,
          time: moment().unix().toString(),
          picture: picture ? picture?.base64 : undefined,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [active_clocking, active_department, camera]);

  const styles = useMemo(() => {
    return StyleSheet.create({
      badge: {
        paddingVertical: scale(2),
        borderRadius: 5,
        marginHorizontal: scale(5),
        flexDirection: "row",
        alignItems: "center",
      },
      badge_name: {
        paddingLeft: scale(10),
        paddingRight: 0,
      },
      badge_cross: {
        paddingLeft: scale(5),
        paddingRight: scale(5),
      },
      attribute_badge: {
        backgroundColor: active_attribute ? active_attribute.color! : "#fff",
      },
    });
  }, [active_attribute]);

  if (!active_attribute || active_break) return null;

  return (
    <View style={[styles.badge, styles.attribute_badge]}>
      <Text
        size={scale(10)}
        color={
          active_attribute.color ? getContrast(active_attribute.color) : "#000"
        }
        style={styles.badge_name}
      >
        {active_attribute.name}
      </Text>
      <View style={styles.badge_cross}>
        <Button
          onPress={onClearAttribute}
          element={
            <CloseCross
              color={
                active_attribute.color
                  ? getContrast(active_attribute.color)
                  : null
              }
            />
          }
          no_style
        />
      </View>
    </View>
  );
};

export default React.memo(SelectedAttributeBadge);
