import Close from '@/components/Close';
import { AppState } from '@/redux/store';
import CompanyLogo from '@icons/CompanyLogo';
import ApiInit from '@screens/api/components/ApiInit';
import NoApiKey from '@screens/api/components/NoApiKey';
import DefaultContainer from '@screens/default/DefaultContainer';
import React, { useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, View } from 'react-native';
import { ScaledSheet } from 'react-native-size-matters';
import { useSelector } from 'react-redux';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Props {}

const ApiScreen: React.FC<Props> = () => {
  const { theme } = useSelector((state: AppState) => state.app);
  const [registeredApiKey, setRegisteredApiKey] = useState<string | null>(null);

  useEffect(() => {
    getApiKey();
  }, []);

  const getApiKey = async () => {
    if (Platform.OS !== 'web') {
      const api_key = await SecureStore.getItemAsync('api_key', {
        keychainAccessible: SecureStore.ALWAYS,
      });
      if (api_key) {
        setRegisteredApiKey(api_key);
      }
    } else {
      const api_key = await AsyncStorage.getItem('api_key');
      if (api_key) {
        setRegisteredApiKey(api_key);
      }
    }
  };

  return (
    <DefaultContainer>
      {registeredApiKey ? <Close relog apiKey={registeredApiKey} /> : null}
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'height' : 'height'}
        style={styles.container}
      >
        <CompanyLogo center marginBottom={25} custom={theme.logo} />
        <ApiInit />
        <View style={styles.no_api_key}>
          <NoApiKey />
        </View>
      </KeyboardAvoidingView>
    </DefaultContainer>
  );
};

const styles = ScaledSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  no_api_key: {
    marginTop: '25@s',
  },
});

export default ApiScreen;
