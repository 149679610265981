import Button from "@/components/ui/Button";
import { AppState } from "@/redux/store";
import { getContrast } from "@/utils";
import Text from "@ui/Text";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EmitterSubscription,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { scale } from "react-native-size-matters";
import { useSelector } from "react-redux";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  checkout: (comment: string | null) => void;
  setLastInteraction: React.Dispatch<React.SetStateAction<number>>;
}

const ONE_HOUR_IN_MS = 60 * 60 * 1000;

const ModalComment: React.FC<Props> = ({
  visible,
  setVisible,
  checkout,
  setLastInteraction,
}) => {
  const { t } = useTranslation();
  const { width } = useSelector((state: AppState) => state.device);
  const { theme, active_department } = useSelector(
    (state: AppState) => state.app
  );
  const { active_clocking, clock_loading } = useSelector(
    (state: AppState) => state.clockings
  );
  const [comment, setComment] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let listener: EmitterSubscription | null;
    if (visible) {
      listener = Keyboard.addListener("keyboardDidShow", (x) => {
        setLastInteraction(Date.now() + ONE_HOUR_IN_MS);
      });
    }

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [visible]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const onBackdropPress = useCallback(() => {
    return setVisible(false);
  }, []);

  const onChangeComment = useCallback(
    (text: string) => {
      setComment(text);
    },
    [setComment]
  );

  const onCheckout = useCallback(async () => {
    if (active_department?.params.comment_mandatory) {
      if (comment) {
        setError(false);
        checkout(comment);
      } else {
        setError(true);
      }
    } else {
      setError(false);
      checkout(comment);
    }
  }, [active_department, comment, active_clocking]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.secondary!,
          padding: scale(15),
          borderRadius: 5,
          width: width / 1.5,
          alignSelf: "center",
        },
        textarea: {
          padding: scale(5),
          height: scale(100),
        },
      }),
    [theme, width]
  );

  return (
    <Modal
      animationInTiming={1}
      animationOutTiming={1}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <View style={styles.container}>
          <Text color={getContrast(theme.secondary!)} size={scale(7)}>
            {active_department?.params.comment_mandatory ? (
              <Text color={theme.error!}>* </Text>
            ) : null}
            {t("GLOBAL.COMMENT")}
          </Text>
          <Text
            area
            numberOfLines={10}
            color={error ? theme.error! : theme.primary!}
            size={scale(7)}
            style={styles.textarea}
            value={comment}
            onChangeText={onChangeComment}
          />
          <Button
            value={t("PROFILE.CLOCK_OUT")}
            primary
            onPress={onCheckout}
            marginTop={scale(10)}
            loading={clock_loading}
          />
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default React.memo(ModalComment);
